import React from "react";
import { TableHeader } from "@ingka/table";

export const StoresTableHeader = () => {
  return (
    <TableHeader sticky={true}>
      <tr>
        <th>Name </th>
        <th>Country </th>
        <th>Store number </th>
        <th>Active </th>
      </tr>
    </TableHeader>
  );
};
