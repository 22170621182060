import { BasicStoreData } from "../components/stores/types/Stores.types";
import { TeamData } from "../components/teams/types/Teams.types";
import { UserRole } from "../types/roles.types";

export const buildQueryParams = (
  countryId: string | undefined,
  storeId: string | undefined,
  roleId: string | undefined
) => {
  const queryParams = new URLSearchParams();
  if (countryId && !countryId.includes(" ")) {
    queryParams.append("countryId", countryId);
  }
  if (storeId && !storeId.includes(" ")) {
    queryParams.append("storeId", storeId);
  }
  if (roleId) {
    queryParams.append("roleId", roleId);
  }
  return queryParams;
};

export function getRoleName(roleId: UserRole) {
  switch (roleId) {
    case "SUPERUSER":
      return "Global Superuser";
    case "COUNTRY_SUPERUSER":
      return "Country Superuser";
    case "COWORKER":
      return "Co-Worker";
    case "STOREOWNER":
      return "Store Superuser";
    default:
      return "";
  }
}

export function getStoreName(stores: BasicStoreData[], storeId: string) {
  return stores?.find((store) => store.id === storeId)?.name;
}
export function checkForPqrTeamInStore(teamsInStore: TeamData[]) {
  return teamsInStore?.some((store) => store.is_product_quality) || false;
}

export function correctArticleNumberLength(item_no: string) {
  return item_no.toString().length < 8 ? item_no.toString().padStart(8, "0") : item_no;
}
