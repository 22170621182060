import { useQuery } from "@tanstack/react-query";
import { getTeamsByStoreId } from "../services/teams.service";

const useGetTeamsByStoreId = (storeId: string) => {
  return useQuery({
    queryKey: ["teams", storeId],
    queryFn: () => getTeamsByStoreId(storeId),
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
export default useGetTeamsByStoreId;
