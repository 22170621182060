import { msalPCA } from "../../../services/auth.service";
import { User } from "../types/users.types";
import { useUserByEmail } from "./useGetUserByEmail";
import { AccountInfo, PublicClientApplication } from "@azure/msal-browser";

export function useLoggedInUser(): { user: User | undefined; isLoading: boolean } {
  let accounts: AccountInfo[] | undefined;
  if (msalPCA && msalPCA instanceof PublicClientApplication) {
    accounts = msalPCA.getAllAccounts() as AccountInfo[];
  } else {
    console.error("msalPCA is not a PublicClientApplication");
  }

  if (!Array.isArray(accounts) || accounts.length === 0) {
    throw new Error("No accounts found");
  }
  const account: AccountInfo = accounts[0];
  if (!account || !account.username) {
    throw new Error("No valid account or email found");
  }
  let email: string = account.username;

  // Edge cases we have username has firstname.lastname@ikea.com, myIdentity is working on to fix the upn, so it is a temporary fix
  const emailDomain: string | undefined = email.split("@")[1];
  if (!emailDomain) {
    throw new Error("Invalid email format");
  }
  const isEmailWithOnlyIkeaDomain: boolean = emailDomain.toLowerCase() === "ikea.com";
  if (isEmailWithOnlyIkeaDomain) {
    email = email.replace("ikea", "ingka.ikea");
  }
  const { data: user, isLoading } = useUserByEmail(email);

  if (!user && !isLoading) {
    throw new Error("User not found");
  }

  return { user, isLoading };
}
