import React from "react";
import Switch from "@ingka/switch";
import { AddStoreData } from "../types/Stores.types";

interface IStoreTableRowProps {
  store: AddStoreData;
  onStoreStatusChange: (store: AddStoreData) => void;
}

const StoreTableRow: React.FC<IStoreTableRowProps> = ({ store, onStoreStatusChange }) => {
  return (
    <tr>
      <td>{store.name}</td>
      <td>{store.countryId}</td>
      <td>{store.storeId}</td>
      <td>
        <Switch
          id={store.name}
          value={store.name}
          checked={store.status === "ACTIVE"}
          onChange={() => {
            onStoreStatusChange(store);
          }}
        />
      </td>
    </tr>
  );
};

export default StoreTableRow;
