type access_token = {
  token?: string;
  expires_at?: number;
};

type User = {
  displayName: string;
  givenName: string | null;
  mail: string;
  preferredLanguage: null;
  surname: string | null;
  userPrincipalName: string;
  id: string;
  employeeId: null;
};

type Data = {
  value: User[];
};

const searchUsers = async (query: string | number | boolean, access_token?: string) => {
  const urlEncoded = encodeURIComponent(query).replace(/'/g, "''");
  const dataPromise = fetch(
    `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${urlEncoded}') or startswith(mail,'${urlEncoded}')&$top=10&$select=displayName,givenName,mail,preferredLanguage,surname,userPrincipalName,id,employeeid`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      method: "GET",
    }
  );

  return dataPromise.then((response) => response.json() as Promise<Data>).then((data) => data.value);
};

export async function graphSearchUsers(query: string | number | boolean, access_token: access_token) {
  return searchUsers(query, access_token.token);
}
