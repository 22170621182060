import { useQuery } from "@tanstack/react-query";
import { getGroupsByRoom } from "../../services/groups.service";

const useGroupsByRoomId = (roomId: string) => {
  return useQuery({
    queryKey: ["groupsByRoom", { id: roomId }],
    queryFn: () => getGroupsByRoom(roomId),
  });
};

export default useGroupsByRoomId;
