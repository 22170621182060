import React from "react";
import { Route, Routes } from "react-router-dom";
import { UsersPage } from "./users/organisms/UsersPage";
import { TeamsTable } from "./teams/organisms/TeamsTable";
import { AllStoresTable } from "./stores/organisms/AllStoresTable";
import { StoreTable } from "./stores/organisms/StoreTable";
import { StoreContactsTable } from "./storeContacts/organisms/StoreContactsTable";
import { RoomSettingByRoomId } from "./roomsettings/organisms/RoomSettingByRoomId";
import { RoomSettingsByStore } from "./roomsettings/organisms/RoomSettingsByStore";

interface TabRouterProps {
  isAdmin: boolean;
  isChina: boolean;
  isFromGermany: boolean;
}

export const TabRouter = ({ isAdmin, isChina, isFromGermany }: TabRouterProps) => {
  return (
    <Routes>
      {isAdmin && (
        <>
          <Route path="/" element={<AllStoresTable />} />
          <Route path="/users" element={<UsersPage />} />
          {/* Team routes */}
          <Route path="/teams" element={<TeamsTable />} />
          {/* Store routes */}
          <Route path="/stores" element={<AllStoresTable />} />
          <Route path="/stores/:storeNumber" element={<StoreTable />} />
          <Route path="/storecontacts" element={<StoreContactsTable />} />
        </>
      )}
      {!(isChina || isFromGermany) && (
        <>
          {/* Room settings routes */}
          {!isAdmin && <Route path="/" element={<RoomSettingsByStore />} />}
          <Route path="/roomsettings" element={<RoomSettingsByStore />} />
          <Route path="/roomsettings/store/:storeNumber" element={<RoomSettingsByStore />} />
          <Route path="/roomsettings/store/:storeNumber/:roomId" element={<RoomSettingByRoomId />} />
        </>
      )}
    </Routes>
  );
};
