import { useQuery } from "@tanstack/react-query";
import { getAllTeams } from "../services/teams.service";

const useTeams = () => {
  return useQuery({
    queryKey: ["teams"],
    queryFn: getAllTeams,
  });
};

export default useTeams;
