import { RoomSetting } from "../types/rooms.types";

export const sortRooms = (rooms: RoomSetting[]) => {
  return rooms.sort((a, b) => {
    const nameComparison = a.name.localeCompare(b.name);
    if (nameComparison !== 0) return nameComparison;

    return 0;
  });
};
