import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table, { TableBody } from "@ingka/table";
import Switch from "@ingka/switch";
import { RoomSetting } from "../types/rooms.types";
import UpdateRoomStatusModal from "../molecules/UpdateRoomStatusModal";
import { RoomSettingsTableHeader } from "../molecules/RoomSettingsTableheader";
import useUpdateRoom from "../hooks/rooms/useUpdateRoom";
import { sortRooms } from "../molecules/RoomsTable.helper";
import { InlineMessageBox } from "../atoms/InlineMessageBox";
import { useImagesByRoomIds } from "../hooks/images/useImagesByRoomIds";
import { RoomSettingImages } from "../types/rooms.types";
import { WarningTriangleSmall } from "../atoms/icons/WarningTriangleSmall";

interface RoomSettingsTableProps {
  rooms: RoomSetting[];
}

export const RoomSettingsTable = ({ rooms }: RoomSettingsTableProps) => {
  const [roomClicked, setRoomClicked] = useState<RoomSetting>({} as RoomSetting);
  const [clickedRoomId, setClickedRoomId] = useState<string | null>(null);
  const [activeClicked, setActiveClicked] = useState(false);
  const updateRoomStatusMutation = useUpdateRoom(roomClicked.id || "", roomClicked.store_id || "");
  const navigate = useNavigate();
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const lastTwoRooms = rooms.slice(-2);

  const roomIds = rooms.map((room) => room.id);
  const { imageQueries, isLoading } = useImagesByRoomIds(roomIds as string[]);

  const [roomImages, setRoomImages] = useState<Record<string, RoomSettingImages | undefined>>({});

  useEffect(() => {
    const newRoomImages: Record<string, RoomSettingImages | undefined> = {};

    imageQueries.forEach((query, index) => {
      if (query.isSuccess) {
        const roomId = roomIds[index];
        if (roomId !== undefined) {
          newRoomImages[roomId] = query.data;
        }
      }
    });

    if (JSON.stringify(newRoomImages) !== JSON.stringify(roomImages)) {
      setRoomImages(newRoomImages);
    }
  }, [roomIds, imageQueries]);

  const roomHasImages = (roomId: string) => {
    const images = roomImages[roomId];
    return images !== undefined && images.length > 0;
  };

  return (
    <>
      <Table style={{ zIndex: 1 }} fullWidth inset>
        <RoomSettingsTableHeader />
        <TableBody style={{ backgroundColor: "white" }}>
          {rooms &&
            sortRooms(rooms).map((room) => (
              <tr
                key={room.id}
                onClick={() => {
                  navigate(`/roomsettings/store/${encodeURIComponent(room.store_id)}/${room.id}`);
                }}
              >
                <td>{room.name}</td>
                <td>{room.nbr_articles}</td>
                <td>{room.style_group}</td>
                <td>{room.hf_ltp}</td>
                <td>{room.pricing}</td>
                <td style={{ paddingRight: "220px" }}>
                  <div
                    style={{ display: "flex", position: "relative" }}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
                  >
                    <Switch
                      id={room.id || ""}
                      value={room.name}
                      checked={room.active === "ACTIVE"}
                      onChange={() => {
                        setRoomClicked(room);
                        if (room.active === "ACTIVE") {
                          setActiveClicked(true);
                        } else if (room.active === "INACTIVE" && room.name.length > 0) {
                          updateRoomStatusMutation.mutate({ active: "ACTIVE" });
                        } else if (room.active === "INACTIVE" && !room.name) {
                          setActiveClicked(true);
                        }
                      }}
                    />
                    {!roomHasImages(room.id || "") && (
                      <div
                        id={room.id || ""}
                        onMouseEnter={() => {
                          setRoomClicked(room);
                          setClickedRoomId(room.id || "");
                          setShowWarningMessage(true);
                        }}
                        onMouseLeave={() => {
                          setShowWarningMessage(false);
                        }}
                      >
                        <div style={{ marginTop: "3px", marginLeft: "5px" }}>
                          <WarningTriangleSmall />
                        </div>
                      </div>
                    )}
                    {showWarningMessage && !roomHasImages(room.id || "") && clickedRoomId === room.id && !isLoading && (
                      <div
                        style={{
                          position: "absolute",
                          top: lastTwoRooms.some((lastRoom) => lastRoom.id === room.id) ? "-130px" : "30px",
                          left: "40px",
                          width: "327px",
                          height: "134px",
                          zIndex: 1,
                        }}
                      >
                        <InlineMessageBox
                          title={"Upload images"}
                          type="cautionary"
                          text="
                              For the optimal experience for the
                              many co-workers, please upload at
                              least one image showing the media.
                          "
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </TableBody>
      </Table>
      {activeClicked && (
        <UpdateRoomStatusModal
          room={roomClicked}
          activeClicked={activeClicked}
          onClose={() => {
            setActiveClicked(false);
          }}
        />
      )}
    </>
  );
};
