import { Article, Item, ItemsInfo, Articles, ProductArticleIdAndQuantity } from "../types/Articles.types";

export const transformArticlesInfos = (
  roomId: string,
  articlesToAdd: ProductArticleIdAndQuantity[],
  articlesInfos: ItemsInfo
): Articles => {
  const mergedArticles: Article[] = [];
  for (const articleToAdd of articlesToAdd) {
    const articleInfo = articlesInfos.find((articleInfo) => articleInfo.no === articleToAdd.productArticleId);
    mergedArticles.push({
      active: "ACTIVE",
      hfb: articleInfo?.hfbNo || "",
      nbrArticles: articleToAdd.quantity,
      itemName: articleInfo?.name || "Unknown",
      itemType: articleInfo?.description,
      itemColor: articleInfo?.description?.split(",")?.[1] || "",
      imageSmall: articleInfo?.smallImageUrl || articleInfo?.imageUrl || "",
      productArticleType: "ART",
      productArticleId: articleToAdd.productArticleId,
      roomId: roomId || "",
    } as Article);
  }
  return mergedArticles;
};

export const mapArticleInfoToArticle = (article: Article, articleInfo: Item): Article => {
  return {
    ...article,
    hfb: articleInfo.hfbNo || "",
    itemName: articleInfo.name || "Unknown",
    itemType: articleInfo?.description,
    itemColor: articleInfo.description?.split(",")?.[1] || "",
    imageSmall: articleInfo?.smallImageUrl || articleInfo?.imageUrl || "",
    productArticleType: "ART",
    productArticleId: articleInfo.no,
  } as Article;
};
