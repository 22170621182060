import { useQuery } from "@tanstack/react-query";
import { fetchUsersInAdmin } from "../services/users.service";

export const useFetchUsersInAdmin = (
  offset: number,
  countryId: string | undefined,
  storeId: string | undefined,
  roleId: string | undefined
) => {
  const stringOffset = offset.toString();
  return useQuery({
    queryKey: ["usersInAdmin", stringOffset, countryId, storeId, roleId],
    queryFn: () => fetchUsersInAdmin(stringOffset, countryId, storeId, roleId),
    staleTime: 10000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
