import Button from "@ingka/button";
import React from "react";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";

interface OkCancelModalProps {
  header: string;
  title: string;
  message: string | JSX.Element;
  isVisible: boolean;
  onOK: () => Promise<void>;
  onCancel: () => void;
}

export const ConfirmCancelModal = ({ header, title, message, isVisible, onOK, onCancel }: OkCancelModalProps) => {
  return (
    <>
      <Modal visible={isVisible} handleCloseBtn={() => onCancel()}>
        <Prompt
          title={title}
          header={<ModalHeader ariaCloseTxt={header} />}
          footer={
            <ModalFooter>
              <Button
                type="secondary"
                text={"Cancel"}
                onClick={() => {
                  onCancel();
                }}
              />
              <Button
                type="danger"
                style={{ backgroundColor: "#e00751", color: "#fff" }}
                text={"Confirm"}
                onClick={async () => {
                  await onOK();
                }}
              />
            </ModalFooter>
          }
          titleId={"ConfirmCancelTitleId "}
        >
          <p>{message}</p>
        </Prompt>
      </Modal>
    </>
  );
};
