import { Locale } from "../components/stores/types/Stores.types";
import { UserRole } from "../types/roles.types";

export const locales: Locale[] = [
  { isoCode: "AT", country: "Austria", isoCodeLang: "de", language: "German (Austria)" },
  { isoCode: "CA", country: "Canada", isoCodeLang: "fr", language: "French (Canada)" },
  { isoCode: "CH", country: "Switzerland", isoCodeLang: "de", language: "German (Switzerland)" },
  { isoCode: "CN", country: "China", isoCodeLang: "zh", language: "Chinese" },
  { isoCode: "CZ", country: "Czechia", isoCodeLang: "cs", language: "Czech" },
  { isoCode: "DE", country: "Germany", isoCodeLang: "de", language: "German" },
  { isoCode: "DK", country: "Denmark", isoCodeLang: "da", language: "Danish" },
  { isoCode: "ES", country: "Spain", isoCodeLang: "es", language: "Spanish" },
  { isoCode: "FI", country: "Finland", isoCodeLang: "fi", language: "Finnish" },
  { isoCode: "FR", country: "France", isoCodeLang: "fr", language: "French" },
  { isoCode: "GB", country: "United Kingdom", isoCodeLang: "en", language: "English (United Kingdom)" },
  { isoCode: "HR", country: "Croatia", isoCodeLang: "hr", language: "Croatian" },
  { isoCode: "HU", country: "Hungary", isoCodeLang: "hu", language: "Hungarian" },
  { isoCode: "IE", country: "Ireland", isoCodeLang: "en", language: "English (United Kingdom)" },
  { isoCode: "IT", country: "Italy", isoCodeLang: "it", language: "Italian" },
  { isoCode: "JP", country: "Japan", isoCodeLang: "ja", language: "Japanese" },
  { isoCode: "KR", country: "South Korea", isoCodeLang: "ko", language: "Korean" },
  { isoCode: "NL", country: "Netherlands", isoCodeLang: "nl", language: "Dutch" },
  { isoCode: "NO", country: "Norway", isoCodeLang: "no", language: "Norwegian" },
  { isoCode: "PL", country: "Poland", isoCodeLang: "pl", language: "Polish" },
  { isoCode: "PT", country: "Portugal", isoCodeLang: "pt", language: "Portuguese" },
  { isoCode: "RO", country: "Romania", isoCodeLang: "ro", language: "Romanian" },
  { isoCode: "RS", country: "Serbia", isoCodeLang: "sr", language: "Serbian" },
  { isoCode: "RU", country: "Russia", isoCodeLang: "ru", language: "Russian" },
  { isoCode: "SE", country: "Sweden", isoCodeLang: "sv", language: "Swedish" },
  { isoCode: "SI", country: "Slovenia", isoCodeLang: "sl", language: "Slovenian" },
  { isoCode: "SK", country: "Slovakia", isoCodeLang: "sk", language: "Slovak" },
  { isoCode: "US", country: "United States", isoCodeLang: "en", language: "English (United States)" },
];
// map for every user role
export const UserRolesArray: { name: string; value: UserRole }[] = [
  {
    name: "Global Super User",
    value: "SUPERUSER",
  },
  {
    name: "Country Super User",
    value: "COUNTRY_SUPERUSER",
  },
  {
    name: "Store Super User",
    value: "STOREOWNER",
  },
  {
    name: "Co-worker",
    value: "COWORKER",
  },
];

type RolesSelection = {
  [key in UserRole]: UserRole[];
};

const accessibleRolesbyUserRole: RolesSelection = {
  SUPERUSER: ["SUPERUSER", "COUNTRY_SUPERUSER", "STOREOWNER", "COWORKER"],
  COUNTRY_SUPERUSER: ["COUNTRY_SUPERUSER", "STOREOWNER", "COWORKER"],
  STOREOWNER: ["STOREOWNER", "COWORKER"],
  COWORKER: [],
  MANAGER: [],
};

export const getAccessibleRoles = (role: UserRole): UserRole[] => {
  return accessibleRolesbyUserRole[role] || [];
};

export enum TitleLabelSize {
  DEFAULT = "DEFAULT",
  LARGE = "LARGE",
}

export const StyleGroupData = [
  "Scandinavian modern",
  "Scandinavian traditional",
  "International modern",
  "International traditional",
];

export const HFLTPData = ["Living with children", "Small space living", "Organise your living"];

export const PricingData = ["Lowest / BTI", "Low", "Medium", "High"];

export const LivingSituationData = [
  "Living single",
  "Living together",
  "Living with children",
  "Multi-generational living",
];
