import React from "react";
import styled from "styled-components";

export const CenterContainer = styled.div`
  justify-content: center;
  text-align: center;
  gap: 24px;
  margin: 48px;
`;

export const CoworkerNoAccessPage = () => {
    return (
        <CenterContainer>
        <h2>
            As a co-worker you do not have access to Fixa Admin
        </h2>
        </CenterContainer>
    );
};
