import React from "react";
import { AddStoreData } from "../types/Stores.types";

interface IStoreTableRowProps {
  store: AddStoreData;
}

const StoreTableRow: React.FC<IStoreTableRowProps> = ({ store }) => {
  return (
    <tr>
      <td>{store.name}</td>
      <td>{store.countryId}</td>
      <td>{store.storeId}</td>
    </tr>
  );
};

export default StoreTableRow;
