import { useQuery } from "@tanstack/react-query";
import { getStoreContactsByStoreId } from "../services/storeContacts.service";

const useStoreContacts = (storeId: string) => {
  return useQuery({
    queryKey: ["store-contacts", storeId],
    queryFn: () => getStoreContactsByStoreId(storeId),
  });
};

export default useStoreContacts;
