import { useQuery } from "@tanstack/react-query";
import { getUserByEmail } from "../services/users.service";
import { isChineseEnvironment } from "../../../services/shared.service";
import { isUserAdmin } from "../../../utils/roles.helpers";
import { userIsFromGermany } from "../../../utils/region.helpers";
import { User } from "../types/users.types";
import { UserRole } from "../../../types/roles.types";

export const useUserByEmail = (email: string) => {
  return useQuery({
    queryKey: ["user", email],
    queryFn: async (): Promise<User> => {
      const userData = await getUserByEmail(email);
      const isChina = isChineseEnvironment();
      const isAdmin = isUserAdmin(userData.roleId as UserRole);
      const isFromGermany = userIsFromGermany(userData);

      const user: User = {
        countryId: userData.countryId,
        email: userData.email,
        fixaUid: userData.fixaUid,
        hashedUid: userData.hashedUid,
        lastActiveAt: userData.lastActiveAt,
        lastLoginAt: userData.lastLoginAt,
        roleId: userData.roleId as UserRole,
        status: userData.status,
        storeId: userData.storeId,
        teamId: userData.teamId,
        uid: userData.uid,
        isAuthorised: false,
        givenName: userData.given_name,
        familyName: userData.family_name,
      };

      // TODO : remove else block and add proper error handling after identifying the issue
      if (user.status === "ACTIVE" && (isAdmin || !(isChina || isFromGermany))) {
        user.isAuthorised = true;
      } else {
        console.log(`User status: ${user.status}`);
        console.log(`Is admin: ${isAdmin}`);
        console.log(`Is from China: ${isChina}`);
        console.log(`Is from Germany: ${isFromGermany}`);
      }

      return user;
    },
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
