import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateRoom } from "../../services/rooms.service";
import { PartialRoom } from "../../types/rooms.types";

const useUpdateRoom = (roomId: string, storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialRoom) => {
      return updateRoom(roomId, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["room", { id: roomId }] });
      return queryClient.invalidateQueries({ queryKey: ["rooms", { id: storeId }] });
    },
  });
};

export default useUpdateRoom;
