import { useQuery } from "@tanstack/react-query";
import { getTeamsByStoreId } from "../services/teams.service";

const useGetTeamsByStoreId = (storeId: string) => {
  return useQuery({
    queryKey: ["teams", storeId],
    queryFn: () => getTeamsByStoreId(storeId),
  });
};
export default useGetTeamsByStoreId;
