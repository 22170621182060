import { RoomSettingImagesCloud, RoomSettingImages, Ids, ImageFileType, RoomSettingImage } from "../types/rooms.types";
import { getMsalToken } from "../../../services/auth.service";
import { roomSettingsServiceBaseUrl, makeServiceHeaders } from "../../../services/shared.service";

const BASE_URL = `${roomSettingsServiceBaseUrl()}/images`;

export const addRoomImages = async (images: RoomSettingImages) => {
  const response = await fetch(`${BASE_URL}/rooms/${images[0].roomId}/image-upload`, {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(images),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data: Ids = (await response.json()) as Ids;
  return data;
};

export const addRoomImageToCloud = async (imageFile: File) => {
  const token = await getMsalToken();
  const formData = new FormData();
  formData.append("file", imageFile);

  const response = await fetch(`${BASE_URL}/image-upload`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data: RoomSettingImagesCloud = (await response.json()) as RoomSettingImagesCloud;
  return data;
};

export const updateImage = async (imageId: string, image: RoomSettingImage) => {
  const response = await fetch(`${BASE_URL}/` + imageId, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(image),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return await response.text();
};

export const getRoomImagesByRoomId = async (roomId: string | undefined) => {
  if (!roomId) return Promise.resolve(undefined);

  const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
    method: "GET",
    headers: await makeServiceHeaders(),
  });

  if (!response.ok) {
    if (response.status === 404) {
      return [];
    } else {
      throw new Error("Network response was not ok.");
    }
  }

  const data: RoomSettingImages = (await response.json()) as RoomSettingImages;
  return data;
};

export const getSignedUrl = async (images: ImageFileType[], size: "large") => {
  const filenames = images.map((item) => item?.file_name).join(",");
  try {
    const response = await fetch(`${BASE_URL}/get_signed_urls?filenames=${filenames}&size=${size}`, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: ImageFileType = (await response.json()) as ImageFileType;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const deleteRoomImage = async (imageId: string, roomId: string) => {
  try {
    const token = await getMsalToken();
    const response = await fetch(`${BASE_URL}/room/${roomId}/images/${imageId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
