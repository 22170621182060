import React, { useState } from "react";
import Table, { TableBody } from "@ingka/table";
import Select, { Option } from "@ingka/select";
import Switch from "@ingka/switch";
import useTeams from "../hooks/useTeams";
import { Team } from "../types/Teams.types";
import { AddTeamForm } from "./AddTeamForm";
import { UpdateTeamStatusModal } from "../molecules/UpdateTeamStatusModal";
import { AllCountries, AllStoresByCountry } from "../../users/organisms/users";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import useStore from "../../stores/hooks/useStore";
import { TeamsTableHeader } from "../molecules/TeamsTableHeader";
import { shouldShowCountrySelector, shouldShowStoreSelector } from "../../../utils/roles.helpers";
import useGetTeamsByStoreId from "../hooks/useGetTeamsByStoreId";
import { TeamUsersTable } from "./TeamUsersTable";
import { UpdateTeamForm } from "./UpdateTeamForm";
import { TableToolsHeader } from "../../layout/TableToolsHeader";
import { useFetchUsers } from "../../users/hooks/useFetchUsers";
import { SkapaToast } from "../../SkapaToast";
import { isChineseEnvironment } from "../../../services/shared.service";

export const TeamsTable = () => {
  const { user } = useLoggedInUser();
  const { data: teams } = useTeams();
  const teamId = user?.teamId;
  const [editModal, setEditModal] = useState(false);
  const [activeClicked, setActiveClicked] = useState(false);
  const [teamClicked, setTeamClicked] = useState({} as Team);
  const [selectedTeam, setSelectedTeam] = useState<{ id: string; name: string }>({ id: "", name: "" });

  const [storeId, setStoreId] = useState<string>(user ? user.storeId : "");
  const [countryId, setCountryId] = useState<string>(user ? user.countryId : "");
  const [showTeamUsersTable, setShowTeamUsersTable] = useState(false);
  const { data: teamsByStoreId } = useGetTeamsByStoreId(storeId);
  const { data: store, isLoading: isLoadingStore } = useStore(storeId);
  const storeName = !isLoadingStore ? store?.data?.data?.name : "";
  const showCountry = user ? shouldShowCountrySelector(user?.roleId) : false;
  const showStores = user ? shouldShowStoreSelector(user?.roleId) : false;
  const allUsers = useFetchUsers(countryId, storeId, "").data;
  const [toastMessage, setToastMessage] = useState<string>("");
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

  const getUsersCountInTeam = (teamId: string) => {
    const teamUsers = allUsers?.filter((user) => user.teamId === teamId);
    return teamUsers?.length || 0;
  };

  return (
    <>
      <TableToolsHeader
        setShowTeamUsersTable={setShowTeamUsersTable}
        showTeamUsersTable={showTeamUsersTable}
        selectedTeam={selectedTeam}
        tableTitle="All teams"
        headerControls={
          <AddTeamForm
            teamId={teamId || ""}
            setSuccessMessage={(message: string | ((prevState: string) => string)) => {
              setToastMessage(message);
              setIsToastOpen(true);
            }}
            onClose={() => setEditModal}
          />
        }
        filtersLeft={
          <>
            {!isChineseEnvironment() && (
              <Select
                id={"countryselect"}
                label={"Country"}
                value={countryId}
                children={<AllCountries />}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setCountryId(event.target.value === "Choose an option" ? "" : event.target.value);
                }}
                disabled={!showCountry}
              />
            )}
            <Select
              id={"storeselect"}
              label={"Store"}
              value={storeId}
              children={<AllStoresByCountry country={countryId} />}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setStoreId(event.target.value === "Choose an option" ? "" : event.target.value);
              }}
              disabled={!showStores}
            />
            <Select
              id={"teamselect"}
              label={"Team"}
              value={selectedTeam.id}
              children={teamsByStoreId
                ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((team) => <Option key={team.id} value={team.id} name={team.name} />)}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = event.target.value;
                if (selectedValue === "Choose an option") {
                  setSelectedTeam({ id: "", name: "" });
                } else {
                  const teamName = teamsByStoreId?.find((team) => team.id === selectedValue)?.name || "";
                  setSelectedTeam({ id: selectedValue, name: teamName });
                  setShowTeamUsersTable(true);
                }
              }}
              disabled={!countryId && !storeId}
            />
          </>
        }
      />
      {showTeamUsersTable ? (
        <TeamUsersTable
          country={countryId}
          store={storeId}
          role=""
          teamId={selectedTeam.id}
          teamName={selectedTeam.name}
        />
      ) : (
        <Table style={{ zIndex: 1 }} fullWidth inset>
          <TeamsTableHeader />
          <TableBody style={{ backgroundColor: "white", cursor: "pointer" }}>
            {teams &&
              teams
                ?.filter((team) => (storeId ? team.storeId === storeId : team))
                .sort((a) => (a.status === "ACTIVE" ? -1 : a.status === "INACTIVE" ? 1 : 0))
                .map((team) => {
                  return (
                    <tr key={team.id}>
                      <td
                        onClick={() => {
                          setTeamClicked(team);
                          setEditModal(true);
                        }}
                      >
                        {team.name}
                      </td>
                      <td
                        onClick={() => {
                          setShowTeamUsersTable(!showTeamUsersTable);
                          setSelectedTeam({ id: team.id, name: team.name });
                        }}
                      >
                        <u>{getUsersCountInTeam(team.id)}</u>
                      </td>
                      <td>{storeName}</td>
                      <td>{String(team.is_product_quality ? "Yes" : "No")}</td>
                      <td>
                        <Switch
                          id={team.id}
                          value={team.name}
                          checked={team.status === "ACTIVE"}
                          disabled={team.is_product_quality}
                          onChange={() => {
                            setTeamClicked(team);
                            setActiveClicked(true);
                          }}
                          onClick={(event) => event.stopPropagation()}
                        />
                      </td>
                    </tr>
                  );
                })}
          </TableBody>
        </Table>
      )}

      {editModal && (
        <UpdateTeamForm
          teamId={teamClicked.id}
          onClose={setEditModal}
          setSuccessMessage={(message: string | ((prevState: string) => string)) => {
            setToastMessage(message);
            setIsToastOpen(true);
          }}
        />
      )}
      {isToastOpen && (
        <SkapaToast text={toastMessage} isOpen={isToastOpen} onCloseRequest={() => setIsToastOpen(false)} />
      )}
      {activeClicked && (
        <UpdateTeamStatusModal
          team={teamClicked}
          activeClicked={activeClicked}
          onClose={() => setActiveClicked(false)}
        />
      )}
    </>
  );
};
