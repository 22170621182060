import { useQuery } from "@tanstack/react-query";
import { getAllTeams } from "../services/teams.service";

const useTeams = () => {
  return useQuery({
    queryKey: ["teams"],
    queryFn: getAllTeams,
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useTeams;
