import { formatWithCommasAndFilterEmpty } from "../../../utils/product.formatter";
import { Article, ArticleInfo, ArticleInfos, Articles, ProductArticleIdAndQuantity } from "../types/Articles.types";

export const transformArticlesInfos = (
  roomId: string,
  articlesToAdd: ProductArticleIdAndQuantity[],
  articlesInfos: ArticleInfos
): Articles => {
  const mergedArticles: Article[] = [];
  for (const articleToAdd of articlesToAdd) {
    const articleInfo = articlesInfos.find((articleInfo) => articleInfo.id === articleToAdd.productArticleId);
    mergedArticles.push({
      active: "ACTIVE",
      hfb: articleInfo?.hfb || "",
      nbrArticles: articleToAdd.quantity,
      itemName: articleInfo?.name || "Unknown",
      itemType: formatWithCommasAndFilterEmpty(articleInfo?.descriptives),
      itemColor: articleInfo?.descriptives[1] || "",
      imageSmall: articleInfo?.image || "",
      productArticleType: "ART",
      productArticleId: articleToAdd.productArticleId,
      roomId: roomId || "",
    } as Article);
  }
  return mergedArticles;
};

export const mapArticleInfoToArticle = (article: Article, articleInfo: ArticleInfo): Article => {
  return {
    ...article,
    hfb: articleInfo.hfb || "",
    itemName: articleInfo.name || "Unknown",
    itemType: formatWithCommasAndFilterEmpty(articleInfo?.descriptives),
    itemColor: articleInfo.descriptives[1] || "",
    imageSmall: articleInfo.image || "",
    productArticleType: "ART",
    productArticleId: articleInfo.id,
  } as Article;
};
