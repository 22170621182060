import React, { useState } from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import { msalLogin } from "../services/auth.service";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #0058a3;
  height: 100vh;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginMessage = styled.div`
  color: var(--yellow);
  margin-bottom: 1rem;
`;

const LogoText = styled.span`
  color: #ffcd3f;
  letter-spacing: -0.2px;
  font-size: 52px;
  font-weight: 700;
`;

const PrivacyContainer = styled.div`
  padding-top: 16px;
  color: #ffffff;
  text-align: center;
`;

interface LoginProps {
  hasAdminRights: boolean;
}

export const Login: React.FC<LoginProps> = ({ hasAdminRights }) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const handleLogin = async () => {
    setLoggingIn(true);
    await msalLogin();
  };

  return (
    <Container>
      <LogoText>Fixa Admin</LogoText>
      <LoginButtonContainer>
        {!hasAdminRights && <LoginMessage>Login error. You do not have admin permission.</LoginMessage>}
        <Button
          data-testid="loginButton"
          onClick={handleLogin}
          loading={loggingIn}
          text={"Sign in with Network ID"}
          type="primary"
        />
      </LoginButtonContainer>
      <PrivacyContainer>
        <Button type="tertiary" text={"Privacy policy"} href="/privacy" inverseTheme></Button>
      </PrivacyContainer>
    </Container>
  );
};
