import React from "react";

import InlineMessage from "@ingka/inline-message";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import "@ingka/focus/dist/style.css";

interface InlineMessageProps {
  title: string;
  text: string;
  type: "positive" | "cautionary" | "negative";
}

export const InlineMessageBox = ({ title, text, type }: InlineMessageProps) => {
  return <InlineMessage title={title} variant={type} body={text} />;
};
