import {
  COUNTRY_SUPERUSER_ROLES,
  GLOBAL_SUPERUSER_ROLES,
  SUPERUSER_ROLES,
  STOREOWNER,
  UserRole,
} from "../types/roles.types";

export function isUserAdmin(roleId: UserRole) {
  return (SUPERUSER_ROLES as readonly string[]).includes(roleId);
}

export function isUserGlobalSuperUser(roleId: UserRole) {
  return (GLOBAL_SUPERUSER_ROLES as readonly string[]).includes(roleId);
}

export function isUserCountrySuperUser(roleId: UserRole) {
  return (COUNTRY_SUPERUSER_ROLES as readonly string[]).includes(roleId);
}

export function isUserStoreOwner(roleId: UserRole) {
  return (STOREOWNER as readonly string[]).includes(roleId);
}

export function shouldShowCountrySelector(roleId: UserRole) {
  return isUserGlobalSuperUser(roleId);
}

export function shouldShowStoreSelector(roleId: UserRole) {
  return isUserCountrySuperUser(roleId) || isUserGlobalSuperUser(roleId);
}

export function shouldShowTeamSelector(roleId: UserRole) {
  return isUserCountrySuperUser(roleId) || isUserGlobalSuperUser(roleId) || isUserStoreOwner(roleId);
}
