import { useQueries } from "@tanstack/react-query";
import { getRoomImagesByRoomId } from "../../services/images.service";

export const useImagesByRoomIds = (roomIds: string[] | undefined) => {
  const imageQueries = useQueries({
    queries:
      roomIds?.map((roomId) => ({
        queryKey: ["roomImages", { id: roomId }],
        queryFn: () => getRoomImagesByRoomId(roomId),
      })) || [],
  });
  const isLoading = imageQueries.some((t) => t.isLoading);
  return { imageQueries, isLoading };
};
