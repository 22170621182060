import React from "react";
import RadioButtonGroup from "@ingka/radio-button-group";
import { Region, getLabelForRegion } from "../utils/region.helpers";

type RegionModalProps = {
  region: Region;
  handleRegionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type RegionOption = {
  id: string;
  label: string;
  value: Region;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RegionModalChildren = ({ region, handleRegionChange }: RegionModalProps) => {
  const regionOptions: RegionOption[] = [
    {
      id: getLabelForRegion("europe"),
      label: getLabelForRegion("europe"),
      value: "europe",
      checked: region === "europe",
      onChange: handleRegionChange,
    },
    {
      id: getLabelForRegion("na"),
      label: getLabelForRegion("na"),
      value: "na",
      checked: region === "na",
      onChange: handleRegionChange,
    },
    {
      id: getLabelForRegion("asia"),
      label: getLabelForRegion("asia"),
      value: "asia",
      checked: region === "asia",
      onChange: handleRegionChange,
    },
  ];

  return <RadioButtonGroup subtle={true} list={regionOptions} />;
};
