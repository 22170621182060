import React from "react";

interface LabelProps {
  text: string;
  withAsterisk?: boolean;
}

export const Label = ({ text, withAsterisk }: LabelProps) => {
  return (
    <p>
      {text}
      {withAsterisk && <span style={{ color: "#E00751" }}>*</span>}
    </p>
  );
};
