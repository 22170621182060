import React, { useState } from "react";
import Button from "@ingka/button";
import Modal, { Sheets, ModalFooter, ModalHeader } from "@ingka/modal";
import Select, { Option } from "@ingka/select";
import InputField from "@ingka/input-field";
import FormField from "@ingka/form-field";
import { RoomId, RoomSetting, RoomSettingImage, RoomSettingImages } from "../types/rooms.types";
import { ModalItemWrapper } from "../../../config/styles";
import useCreateRoom from "../hooks/rooms/useCreateRoom";
import useRoomsByStoreId from "../hooks/rooms/useRoomsById";
import { StyleGroupData, HFLTPData, PricingData, LivingSituationData } from "../../../data/constants";
import { PaddedHorizontalContainer, CarouselImageSize } from "../../../styles/styles";
import { Label } from "../atoms/Label";

import tag from "@ingka/ssr-icon/paths/tag";
import paintbrush from "@ingka/ssr-icon/paths/paintbrush";
import bike from "@ingka/ssr-icon/paths/bike";
import home from "@ingka/ssr-icon/paths/home";
import flag from "@ingka/ssr-icon/paths/flag";
import stairs from "@ingka/ssr-icon/paths/stairs";
import family from "@ingka/ssr-icon/paths/family-gender-mixed";

import { useUploadImageToCloud } from "../hooks/images/useUploadImageToCloud";
import { useUploadImage } from "../hooks/images/useUploadImage";
import Carousel from "@ingka/carousel";
import { FileUploadButton } from "../molecules/FileUploadButton";
import TextArea from "@ingka/text-area";
import { Rotate } from "../atoms/icons/Rotate";
import { Departments } from "../../../data/departments";
import { MessageModal } from "../../../modals/MessageModal";

interface StoreData {
  name: string;
  store_id: string;
}

interface AddRoomSettingsFormProps {
  store: StoreData;
}

export const AddRoomSettingsForm = ({ store }: AddRoomSettingsFormProps) => {
  const createRoomMutation = useCreateRoom();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [status, setStatus] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState<string | undefined>();
  const [room, setRoom] = useState<RoomSetting>({
    name: "",
    style_group: "",
    hf_ltp: "",
    pricing: "",
    active: "",
    store_id: "",
    nbr_articles: 0,
    main_article_id_1: "",
    main_article_id_2: "",
    main_article_name_1: "",
    main_article_name_2: "",
    backup_article_id_1: "",
    backup_article_id_2: "",
    backup_article_name_1: "",
    backup_article_name_2: "",
    media_story: "",
    living_situation: "",
    living_conditions: "",
    laha: "",
    hfb: "",
  });

  const { data: rooms } = useRoomsByStoreId(store.store_id);

  const uploadImageToCloud = useUploadImageToCloud();
  const uploadImage = useUploadImage();
  const [images, setImages] = useState<RoomSettingImages>([]);

  const checkRoomName = () => {
    return !!rooms?.rooms.find((roomData) => roomData.name === room.name);
  };

  const checkEmptyRoomName = () => {
    const isRoomNameEmpty = !room.name;
    if (isRoomNameEmpty) return true;

    return false;
  };

  const validationMessage = () => {
    if (checkRoomName()) {
      return `Room with name ${room.name} already exists`;
    } else if (checkEmptyRoomName()) {
      return "Room name cannot be empty";
    }
  };

  const handleAddImage = async (file: File) => {
    const uploadedImage = await uploadImageToCloud.mutateAsync(file);
    if (!uploadedImage) return;
    setImages((images) => [
      ...images,
      {
        url: uploadedImage.signed_url,
        roomId: "",
        folder: uploadedImage.folder,
        fileName: uploadedImage.file_name,
        rotation: 0,
      },
    ]);
  };

  const handleRotateImage = (image: RoomSettingImage, index: number) => {
    image.rotation = image.rotation ? (image.rotation + 90) % 360 : 90;
    const carouselImage = document.getElementById(`${image.id}-${index}`);
    if (carouselImage) {
      carouselImage.style.transform = "rotate(" + image.rotation + "deg)";
    }
  };

  const closeForm = () => {
    setModalVisibility(false);
    setRoom({
      name: "",
      style_group: "",
      hf_ltp: "",
      pricing: "",
      active: "",
      store_id: "",
      nbr_articles: 0,
      main_article_id_1: "",
      main_article_id_2: "",
      main_article_name_1: "",
      main_article_name_2: "",
      backup_article_id_1: "",
      backup_article_id_2: "",
      backup_article_name_1: "",
      backup_article_name_2: "",
      media_story: "",
      living_situation: "",
      living_conditions: "",
      laha: "",
      hfb: "",
    });

    setStatus(false);
  };

  const handleSubmit = async () => {
    const roomToBeAdded: RoomSetting = {
      ...room,
      store_id: store.store_id,
      active: status === true ? "ACTIVE" : "INACTIVE",
    };

    let roomId: RoomId | undefined = undefined;
    try {
      roomId = await createRoomMutation.mutateAsync(roomToBeAdded);
      if (roomId && images.length > 0) {
        const updatedImages = images.map((image) => ({
          ...image,
          roomId: roomId?.room_id || "",
        }));
        await uploadImage.mutateAsync(updatedImages);
        setImages([]);
      }

      closeForm();
    } catch (error) {
      console.log(error);
      if (!roomId) {
        setShowModalMessage("Could not create room. Please try again later.");
      } else if (roomId && images.length > 0) {
        setShowModalMessage("Could not create images. Please try again later.");
      } else {
        setShowModalMessage("Something went wrong while creating a room.");
      }
    }
  };

  return (
    <>
      <Button type="emphasised" onClick={() => setModalVisibility(!modalVisibility)}>
        Add room setting
      </Button>

      <Modal visible={modalVisibility} handleCloseBtn={() => setModalVisibility(false)}>
        <Sheets
          labelledById="add-room-setting"
          header={<ModalHeader ariaCloseTxt="Close prompt." title="Add room settings" />}
          size={"small"}
          footer={
            <ModalFooter>
              <Button type="primary" onClick={() => handleSubmit()} disabled={checkRoomName() || checkEmptyRoomName()}>
                Add room setting
              </Button>
            </ModalFooter>
          }
        >
          <PaddedHorizontalContainer>
            <ModalItemWrapper>
              <h2>{store.name}</h2>
              {!!showModalMessage && (
                <MessageModal
                  message={showModalMessage}
                  closeModal={() => {
                    setShowModalMessage(undefined);
                    closeForm();
                  }}
                />
              )}
              <FormField
                shouldValidate={checkRoomName() || checkEmptyRoomName()}
                validation={{
                  msg: validationMessage(),
                }}
              >
                <InputField
                  id={"name"}
                  type={"text"}
                  label={<Label text={"Enter media name"} withAsterisk />}
                  ssrIcon={tag}
                  iconPosition={"leading"}
                  onChange={(event) => {
                    setRoom((room) => ({
                      ...room,
                      name: event.target.value,
                    }));
                  }}
                />
              </FormField>
              <div>
                <Label text={"Photos"} />
                <Carousel id={"room-images"}>
                  {images?.map((image, index) => (
                    <div key={`${image.id}-${index}`} style={{ position: "relative", display: "inline-block" }}>
                      <CarouselImageSize
                        id={`${image.id}-${index}`}
                        key={`${image.id}-${index}`}
                        src={image.url}
                        alt={"Room image"}
                        $rotation={image.rotation || 0}
                      />
                      <div
                        onClick={() => {
                          void handleRotateImage(image, index);
                        }}
                      >
                        <Rotate />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              <FileUploadButton handleFile={handleAddImage} />
              <TextArea
                id={"media_story"}
                label={"Main story for the media"}
                style={{ height: "100px" }}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setRoom((room) => ({
                    ...room,
                    media_story: event.target.value,
                  }));
                }}
              />
              <Select
                id={"style_group"}
                label={"Style group"}
                hintText={"Select style group"}
                value={room.style_group}
                ssrIcon={paintbrush}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    style_group: event.target.value,
                  }));
                }}
              >
                {StyleGroupData.map((styleGroup, index) => (
                  <Option key={index} name={styleGroup} />
                ))}
              </Select>
              <Select
                id={"living_situation"}
                label={"Living situation"}
                hintText={"Select living situation"}
                ssrIcon={family}
                value={room.living_situation}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    living_situation: event.target.value,
                  }));
                }}
              >
                {LivingSituationData.map((livingSituation, index) => (
                  <Option key={index} name={livingSituation} />
                ))}
              </Select>
              <Select
                id={"hf_ltp"}
                label={"Long-term priority"}
                hintText={"Select long-term priority"}
                value={room.hf_ltp}
                ssrIcon={flag}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    hf_ltp: event.target.value,
                  }));
                }}
              >
                {HFLTPData.map((hfltp, index) => (
                  <Option key={index} name={hfltp} />
                ))}
              </Select>
              <Select
                id={"pricing"}
                label={"Pricing"}
                hintText={"Set pricing"}
                value={room.pricing}
                ssrIcon={stairs}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    pricing: event.target.value,
                  }));
                }}
              >
                {PricingData.map((pricing, index) => (
                  <Option key={index} name={pricing} />
                ))}
              </Select>
              <InputField
                id={"life_at_home_activity"}
                type={"text"}
                label={"Life at home activity"}
                value={room.laha}
                ssrIcon={bike}
                iconPosition={"leading"}
                onChange={(event) => {
                  setRoom((room) => ({
                    ...room,
                    laha: event.target.value,
                  }));
                }}
              />
              <InputField
                id={"living_conditions"}
                type={"text"}
                label={"Living conditions"}
                value={room.living_conditions}
                ssrIcon={home}
                iconPosition={"leading"}
                onChange={(event) => {
                  setRoom((room) => ({
                    ...room,
                    living_conditions: event.target.value,
                  }));
                }}
              />
              <Select
                id={"hfb"}
                label={"HFB location in store"}
                hintText={"Select HFB location"}
                value={room.hfb}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    hfb: event.target.value,
                  }));
                }}
              >
                {Departments.map((department, index) => (
                  <Option key={index} name={department.shortName} />
                ))}
              </Select>
            </ModalItemWrapper>
          </PaddedHorizontalContainer>
        </Sheets>
      </Modal>
    </>
  );
};
