import { ProductArticleIdAndQuantity } from "../types/Articles.types";

export const cleanUpArticleId = (articleId: string) => {
  return articleId.replace(/\./g, "").replace(/-/g, "").trim();
};

export const extractProductArticleIdAndQuantity = (inputValue: string): ProductArticleIdAndQuantity[] => {
  return inputValue
    .trim()
    .split(/\n/)
    .map((line) => {
      const [productArticleId, quantity] = line.split(/\s/);
      const cleanedId = cleanUpArticleId(productArticleId);
      return { productArticleId: cleanedId, quantity: quantity ? parseInt(quantity, 10) : 1 };
    })
    .filter((article) => article !== null);
};

export const isValidInput = (inputValue: string) => {
  if (!inputValue) return;

  const lines = inputValue.trim().split(/\n/);
  const productArticleIdRegex = /^[0-9.-]+$/;
  const quantityRegex = /^[0-9]+$/;

  for (const line of lines) {
    const [productArticleId, quantity] = line.split(/\s/);

    if (!productArticleIdRegex.test(productArticleId)) {
      return true;
    }

    const cleanedProductArticleId = cleanUpArticleId(productArticleId);
    if (cleanedProductArticleId.length !== 8) {
      return true;
    }

    if (quantity && !quantityRegex.test(quantity)) {
      return true;
    }
  }

  return false;
};
