import React, { useEffect, useState } from "react";
import Button from "@ingka/button";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import useUpdateStoreStatus from "../hooks/useUpdateStoreStatus";

interface UpdateStoreStatusModalProps {
  store: { storeId: string; status: string; name: string };
  activeClicked: boolean;
  onClose: () => void;
}

export const UpdateStoreStatusModal = ({ store, activeClicked, onClose }: UpdateStoreStatusModalProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const updatedStatus = store.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
  const updateStoreStatusMutation = useUpdateStoreStatus(store.storeId, updatedStatus);

  const handleStoreStatusChange = async () => {
    await updateStoreStatusMutation.mutateAsync();
    setModalVisibility(false);
    onClose();
  };

  useEffect(() => {
    setModalVisibility(activeClicked);
  }, []);

  return (
    <>
      <Modal
        visible={modalVisibility}
        handleCloseBtn={() => {
          onClose();
          setModalVisibility(false);
        }}
      >
        <Prompt
          title="Change active status"
          titleId="change-active-status"
          header={<ModalHeader ariaCloseTxt="Close prompt." />}
          footer={
            <ModalFooter>
              <Button onClick={handleStoreStatusChange}>Change active status</Button>
            </ModalFooter>
          }
        >
          {store.status === "ACTIVE" && <p>Are you sure you want to deactivate {store.name}?</p>}
        </Prompt>
      </Modal>
    </>
  );
};
