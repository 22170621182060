import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { ConfigProvider, Layout, NavItemModelReact } from "@coworker-design/layout-react";
import { TabRouter } from "./TabRouter";
import { useRegionsStore, RegionSwitcher } from "./RegionSwitcher";
import { EditLoggedInUser } from "./users/organisms/users";
import { isChineseEnvironment } from "../services/shared.service";
import { msalLogout } from "../services/auth.service";
import { TitleLabelSize } from "../data/constants";
import { useLoggedInUser } from "./users/hooks/useLoggedInUser";
import { Login } from "./Login";
import { isUserAdmin } from "../utils/roles.helpers";
import { userIsFromGermany } from "../utils/region.helpers";
import { convertUserToActiveUser } from "./users/services/users.service";
import { BouncingBallsLoader } from "./layout/Loader";
import EnvironmentBanner from "./layout/EnvironmentBanner";

const tempNavItems: NavItemModelReact[] = [
  { label: "Stores", href: "/stores" },
  { label: "Teams", href: "/teams" },
  { label: "Users", href: "/users" },
  { label: "Store contacts", href: "/storecontacts" },
  { label: "Media Maintenance (Room Settings)", href: "/roomsettings" },
];

const Home: React.FC = () => {
  const { user, isLoading } = useLoggedInUser();
  const navigate = useNavigate();
  const [region, setRegion] = useRegionsStore((state) => [state.region, state.setRegion]);
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState(location.pathname !== "/" ? location.pathname : "/stores");

  const [isAdmin, setIsAdmin] = useState(false);
  const [isFromGermany, setIsFromGermany] = useState(false);
  const [hasAdminRights, setHasAdminRights] = useState(false);
  const [isChina, setIsChina] = useState(false);
  const [regionSwitchLoader, setRegionSwitchLoader] = useState(false);

  useEffect(() => {
    if (!user) return;
    //TODO: Use interaction status along with user data to determine if user is authorized
    setIsAdmin(isUserAdmin(user.roleId));
    setIsFromGermany(userIsFromGermany(user));
    setHasAdminRights(user.isAuthorised);
    setIsChina(!!isChineseEnvironment());
  }, [user]);

  const filteredNavItems = React.useMemo(() => {
    return tempNavItems
      .filter((navItem) => {
        // All users except from China and Germany can access Room Settings
        if (navItem.href === "/roomsettings") {
          return !(isChina || isFromGermany);
        }
        // Admins can access all tabs
        return isAdmin;
      })
      .map((navItem) => ({
        ...navItem,
        isActive: navItem.href === activeNavItem,
      }));
  }, [isAdmin, activeNavItem, isChina, isFromGermany]);

  return (
    <>
      {!isLoading && !hasAdminRights && <Login hasAdminRights={hasAdminRights} />}
      {hasAdminRights && (
        <ConfigProvider
          defaultTitle="Fixa Admin"
          defaultLogoutCallback={() => {
            msalLogout().catch(console.error);
          }}
          headerNavMode="HEADER_LINKS"
          isShowingBottomNav={false}
          isAppSwitcherShown={false}
          titleSize={TitleLabelSize.LARGE}
          user={convertUserToActiveUser(user)}
          navItems={filteredNavItems}
          onLocationChange={(href, _target, event) => {
            event.preventDefault();
            navigate(href);
            setActiveNavItem(href);
          }}
          sections={
            user && [
              {
                title: "Edit your information",
                description: "Change your store, team or role.",
                template: <EditLoggedInUser user={user} />,
              },
            ]
          }
          onTitleClick={(event: React.SyntheticEvent) => {
            event.preventDefault();
            navigate("/");
            setActiveNavItem("/stores");
          }}
        >
          <Layout
            narrowContent={false}
            utilities={
              !isChina &&
              user &&
              user.roleId === "SUPERUSER" && (
                <RegionSwitcher
                  region={region ?? "europe"}
                  setRegion={setRegion}
                  setRegionLoader={setRegionSwitchLoader}
                />
              )
            }
          >
            <EnvironmentBanner />
            {regionSwitchLoader && <BouncingBallsLoader />}
            <TabRouter isAdmin={isAdmin} isChina={isChina} isFromGermany={isFromGermany} />
          </Layout>
        </ConfigProvider>
      )}
    </>
  );
};

export default Home;
