import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateTeam } from "../services/teams.service";
import { PartialTeam } from "../types/Teams.types";

const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PartialTeam & { team_id: string }) => updateTeam(data.team_id, data),
    onSuccess: async (updatedTeam) => {
      queryClient.setQueryData(["team", updatedTeam], updatedTeam);
      return await queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
};

export default useUpdateTeam;
