import React from "react";
import { TableHeader } from "@ingka/table";

export const TeamsTableHeader = () => {
  return (
    <TableHeader sticky={true}>
      <tr>
        <th>Team name </th>
        <th>Users </th>
        <th>Store </th>
        <th>Product Quality </th>
      </tr>
    </TableHeader>
  );
};
