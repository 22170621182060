import { useQuery } from "@tanstack/react-query";
import { getTeamById } from "../services/teams.service";

const useTeam = (teamId: string) => {
  return useQuery({
    queryKey: ["room", teamId],
    queryFn: () => getTeamById(teamId),
  });
};

export default useTeam;
