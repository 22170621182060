import React, { useState } from "react";
import { TaskLinks } from "../types/taskLinks.types";
import { updateArticle } from "../services/articles.service";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import { useQueryClient } from "@tanstack/react-query";
import arrowLeftRight from "@ingka/ssr-icon/paths/arrow-left-arrow-right";
import { Article, ProductArticleIdAndQuantity } from "../types/Articles.types";
import { hasOngoingTasks } from "./ArticleRow.helper";
import { fetchItemsInfo } from "../../../services/tasks.service";
import { transformArticlesInfos } from "../helpers/transform.articles.helper";

interface ReplaceArticleModalProps {
  taskLinks: TaskLinks;
  article: Article;
}

export const ReplaceArticleModal = ({ taskLinks, article }: ReplaceArticleModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [product, setProduct] = useState<string | undefined>();

  const queryClient = useQueryClient();

  async function onReplaceArticle() {
    if (product) {
      const articleInfos = await fetchItemsInfo(product);

      if (articleInfos) {
        const articleInfo = articleInfos[0];
        if (articleInfo)
          await updateArticle(article.id, {
            ...article,
            ...transformArticlesInfos(
              article.roomId,
              [{ productArticleId: articleInfo.no, quantity: article.nbrArticles } as ProductArticleIdAndQuantity],
              articleInfos
            )[0],
          });
        await queryClient.invalidateQueries({ queryKey: ["roomArticles"] });
      }
    }
  }
  return (
    <>
      <Button type={"tertiary"} ssrIcon={arrowLeftRight} text={"Change"} onClick={() => setModalOpen(!modalOpen)} />
      <Modal visible={modalOpen} handleCloseBtn={() => setModalOpen(!modalOpen)}>
        <Prompt
          title={"Replace product"}
          titleId={"replace-product-modal"}
          header={<ModalHeader ariaCloseTxt={"Close modal"} />}
          footer={
            <ModalFooter>
              {hasOngoingTasks(taskLinks, article.productArticleId) ? (
                <Button text={"Cancel"} onClick={() => setModalOpen(!modalOpen)} />
              ) : (
                <Button
                  text={"Replace product"}
                  onClick={async () => {
                    await onReplaceArticle();
                    setModalOpen(!modalOpen);
                  }}
                />
              )}
            </ModalFooter>
          }
        >
          {hasOngoingTasks(taskLinks, article.productArticleId) ? (
            <p>There are ongoing tasks for this article, Please replace the article in the Fixa app instead</p>
          ) : (
            <InputField
              id={"name"}
              type={"text"}
              label={"Insert article number"}
              onChange={(event) => {
                setProduct(event.target.value);
              }}
            />
          )}
        </Prompt>
      </Modal>
    </>
  );
};
