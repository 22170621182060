import { fetchArticlesInfo } from "../../../services/tasks.service";
import { updateArticle } from "../services/articles.service";
import { Articles } from "../types/Articles.types";
import { mapArticleInfoToArticle } from "./transform.articles.helper";

export const updateUnknownArticles = async (articles: Articles): Promise<void> => {
  if (!articles || articles.length === 0) return;
  const unknownArticles = articles.filter((article) => article.itemName.toLowerCase() === "unknown");
  const articlesInfo = await fetchArticlesInfo(unknownArticles.map((article) => article.productArticleId).join(","));
  const articlesToUpdate: Articles = [];
  articlesInfo.forEach((articleInfo) => {
    const matchingArticles = unknownArticles.filter((article) => article.productArticleId === articleInfo.id);
    if (matchingArticles && matchingArticles.length > 0) {
      matchingArticles.forEach((matchingArticle) => {
        articlesToUpdate.push(mapArticleInfoToArticle(matchingArticle, articleInfo));
      });
    }
  });

  await Promise.all(articlesToUpdate.map((article) => updateArticle(article.id, article)));
};
