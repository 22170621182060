import { useQuery } from "@tanstack/react-query";
import { getAllCountries } from "../services/countries.service";

const useCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: getAllCountries,
    staleTime: 7 * 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useCountries;
