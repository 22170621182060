import { getMsalToken } from "./auth.service";
import packageJson from "../../package.json";
import type { Region } from "../utils/region.helpers";
import Cookies from "universal-cookie";

const ServicePath = {
  CORE_SERVICE: "core-service",
  TASKS_SERVICE: "tasks-service",
};

export type FixaWindow = typeof window & {
  isCN?: boolean;
  toggleHiddenNavigation: () => void;
};

type Environment = "dev" | "stage" | "prod";

export const isChineseEnvironment = () => window.location.host.includes(".cn") || (window as FixaWindow).isCN;

/**
 * Uses the url the app is hosted on to generate the correct environmentId,
 * used for generating backend service baseUrls and such
 */
export function getEnvironmentId(): Environment {
  if (window.location.hostname.includes("localhost")) return "dev";
  const subdomain = window.location.hostname.split(".").at(-3);
  switch (subdomain) {
    case "fixa-admin-dev":
      return "dev";
    case "fixa-admin-stage":
      return "stage";
    case "fixa-admin":
      return "prod";
    default:
      return "prod";
  }
}
const cookies = new Cookies();

export function getRegion(): Region {
  let currentRegion = cookies.get("BackendOrigin") as Region; // RegionSwitcher cookie
  // TODO Fallback to region guess can be removed later since akamai is setting the BackendOrigin cookie to the correct region
  if (!currentRegion) {
    // Guess region based on timezone
    const offset = new Date().getTimezoneOffset();
    if (offset < -4 * 60) currentRegion = "asia" as Region;
    else if (offset > 3 * 60) currentRegion = "na" as Region;
    else currentRegion = "europe" as Region;
    console.log("Region not found in cookies, setting to", { currentRegion, offset });
  }
  return currentRegion;
}

function baseUrl(environmentId: Environment, service: keyof typeof ServicePath, isChina: boolean) {
  //TODO, move this logic to getRegion later
  const RegionSwitcher = cookies.get("RegionSwitcher") as Region;
  console.log("RegionSwitcher", RegionSwitcher);
  const region = RegionSwitcher || getRegion();
  console.log("Region", region);

  console.log(
    `Getting base url for env: ${environmentId}, service: ${service}, isChina: ${isChina}, region: ${region}`
  );
  // CHINA
  if (isChina) {
    switch (environmentId) {
      case "prod":
        return `https://fixa-api.ingka-internal.cn/cn-s1/${ServicePath[service]}`;
      case "dev":
      default:
        return `https://fixa-api-dev.ingka-dt.cn/cn-s1/${ServicePath[service]}`;
    }
  }

  // GLOBAL
  switch (environmentId) {
    case "stage":
      return `https://fixa-api-stage.ingka.com/eu-w1/${ServicePath[service]}`;
    case "prod":
      switch (region) {
        case "europe":
          return `https://fixa-api.ingka.com/eu-w1/${ServicePath[service]}`;
        case "asia":
          return `https://fixa-api.ingka.com/as-e1/${ServicePath[service]}`;
        case "na":
          return `https://fixa-api.ingka.com/us-c1/${ServicePath[service]}`;
      }
    // eslint-disable-next-line no-fallthrough -- intentional, seems a bug in the rule, there is no fallthrough here
    case "dev":
    default:
      return `https://fixa-api-dev.ingka.com/eu-w1/${ServicePath[service]}`;
  }
}
export function coreServiceBaseUrl() {
  return baseUrl(getEnvironmentId(), "CORE_SERVICE", !!isChineseEnvironment());
}

export function tasksServiceBaseUrl() {
  return baseUrl(getEnvironmentId(), "TASKS_SERVICE", !!isChineseEnvironment());
}

export async function makeServiceHeaders(): Promise<HeadersInit> {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${await getMsalToken()}`,
    "X-Client-Version": packageJson.version,
  };
}

export async function callFetch<ResponseT>(
  method: string,
  url: string,
  additionalHeaders = {},
  bodyData = {}
): Promise<{ data: { data: ResponseT } }> {
  const headers = { ...(await makeServiceHeaders()), ...additionalHeaders };
  const params: RequestInit = {
    method,
    headers,
  };
  if ("GET" !== method && bodyData) params.body = JSON.stringify(bodyData);
  const response = await fetch(url, params);
  if (!response.ok) {
    throw new Error("Fetching from core-service failed.");
  }
  return response.json() as Promise<{ data: { data: ResponseT } }>;
}
