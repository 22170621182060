import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStoreContact } from "../services/storeContacts.service";
import { CreateStoreContactData } from "../types/StoreContacts.types";

export const useUpdateStoreContact = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateStoreContactData & { storeContactId: string }) =>
      updateStoreContact(data, data.storeContactId),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["store-contacts"] });
    },
  });
};
