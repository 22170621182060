import { Articles, EdsData, PartialArticle, SalesStopData } from "../types/Articles.types";
import { getMsalToken } from "../../../services/auth.service";
import { roomSettingsServiceBaseUrl } from "../../../services/shared.service";
import { makeServiceHeaders } from "../../../services/shared.service";
import packageJson from "../../../../package.json";

const BASE_URL = `${roomSettingsServiceBaseUrl()}/articles`;

export const addArticlesBulk = async (articles: Articles) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(articles),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as Articles;
  return data;
};

export const updateArticle = async (articleId: string, article: PartialArticle) => {
  if (!articleId) return;

  const url = `${BASE_URL}/${articleId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(article),
  });

  if (!response.ok) {
    throw new Error("Response was not ok.");
  }
};

export const deleteArticle = async (articleId: string) => {
  const url = `${BASE_URL}/${articleId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${await getMsalToken()}`,
      "X-Client-Version": packageJson.version,
    },
  });

  if (!response.ok) {
    throw new Error("Response was not ok.");
  }
};

export const getEDSData = async (storeId: string, articleNumbers: string) => {
  try {
    if (!storeId || !articleNumbers) return [];

    const url = `${BASE_URL}/eds/${encodeURIComponent(storeId)}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return (await response.json()) as EdsData[];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const getSalesStopData = async (storeId: string, articleNumbers: string) => {
  try {
    if (!storeId || !articleNumbers) return [];

    const url = `${BASE_URL}/salesstop/${encodeURIComponent(storeId)}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return (await response.json()) as SalesStopData[];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};
