import React, { useEffect, useState } from "react";
import Button from "@ingka/button";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import { PartialRoom, RoomSetting } from "../types/rooms.types";
import useUpdateRoom from "../hooks/rooms/useUpdateRoom";
import useRoom from "../hooks/rooms/useRoom";
import { useParams } from "react-router-dom";
import { useImagesByRoomId } from "../hooks/images/useImagesByRoomId";
import { WarningTextWithIcon } from "./WarningTextWithIcon";
import { NoticeRedSmall } from "../atoms/icons/NoticeRedSmall";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";

interface UpdateRoomStatusModalProps {
  room: RoomSetting;
  activeClicked: boolean;
  onClose: () => void;
}

const UpdateRoomStatusModal = ({ room, activeClicked, onClose }: UpdateRoomStatusModalProps) => {
  const { storeNumber: storeNumberFromPath } = useParams();
  const { user } = useLoggedInUser();
  const storeNumber = storeNumberFromPath || user?.storeId;
  const [modalVisibility, setModalVisibility] = useState(false);
  const { data: roomImages } = useImagesByRoomId(room.id);
  const updatedStatus = room.active === "ACTIVE" ? "INACTIVE" : "ACTIVE";
  const updateRoomStatusMutation = useUpdateRoom(room.id || "", storeNumber || "");
  const { data } = useRoom(room.id);
  const [updateRoom, setUpdateRoom] = useState<PartialRoom>({
    ...room,
    active: updatedStatus,
  });

  const noRoomName = room.name?.length === 0;

  const handleStoreStatusChange = async () => {
    const updatedRoom = await updateRoomStatusMutation.mutateAsync({
      ...updateRoom,
      active: updatedStatus,
    });
    setUpdateRoom(updatedRoom || {});
    setModalVisibility(false);
    onClose();
  };

  useEffect(() => {
    setModalVisibility(activeClicked);
  }, [roomImages]);

  useEffect(() => {
    if (data?.room) {
      const room = data.room;
      setUpdateRoom({
        ...room,
        active: room.active,
      });
    }
  }, [data]);

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onClose();
        setModalVisibility(false);
      }}
    >
      <Prompt
        titleId="change-active-status"
        title="Change active status"
        header={<ModalHeader ariaCloseTxt="Close prompt." />}
        footer={
          <ModalFooter>
            <Button onClick={handleStoreStatusChange} disabled={noRoomName}>
              Change active status
            </Button>
          </ModalFooter>
        }
      >
        {noRoomName ? (
          <WarningTextWithIcon icon={<NoticeRedSmall />} text="To activate a room, it is required to provide a name" />
        ) : (
          room.active === "ACTIVE" && <p>Are you sure you want to deactivate {room.name}?</p>
        )}
      </Prompt>
    </Modal>
  );
};

export default UpdateRoomStatusModal;
