import React, { useEffect, useState } from "react";
import Table, { TableBody } from "@ingka/table";
import { AddStoreData } from "../types/Stores.types";
import StoreTableRow from "../molecules/StoreTableRow";
import { CountrySelector } from "../molecules/countrySelector";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import useStoresByCountryId from "../hooks/useStoresByCountryId";
import { StoresTableHeader } from "../molecules/StoresTableHeader";
import { shouldShowCountrySelector, isUserStoreOwner } from "../../../utils/roles.helpers";
import { TableToolsHeader } from "../../layout/TableToolsHeader";
import { isChineseEnvironment } from "../../../services/shared.service";

export const AllStoresTable = () => {
  const { user, isLoading } = useLoggedInUser();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredStores, setFiteredStores] = useState<AddStoreData[]>([]);


  const showCountrySelector = user ? shouldShowCountrySelector(user.roleId) : false;
  const { data: stores } = useStoresByCountryId(selectedCountry);

  useEffect(() => {
    if (!isLoading && user) {
      setSelectedCountry(user.countryId);
    }
  }, [user]);

  useEffect(() => {
    if (user && stores) {
      const isStoreOwner = isUserStoreOwner(user.roleId);
      setFiteredStores(isStoreOwner ? stores.filter((store) => store.storeId === user.storeId) : stores);
    }
  }, [stores]);

  return (
    <>
      <TableToolsHeader
        setShowTeamUsersTable={() => {}}
        showTeamUsersTable={false}
        selectedTeam={{ id: "", name: "" }}
        tableTitle="All stores"
        filtersLeft={
          !isChineseEnvironment() && (
            <>
              <CountrySelector
                selectedCountry={selectedCountry}
                setCountry={setSelectedCountry}
                showCountry={showCountrySelector}
              />
            </>
          )
        }
      />

      <Table style={{ zIndex: 1 }} fullWidth inset>
        <StoresTableHeader />
        {selectedCountry ? (
          <TableBody style={{ backgroundColor: "white" }}>
            {filteredStores &&
              filteredStores.map((store) => (
                <StoreTableRow key={store.storeId} store={store} />
              ))}
          </TableBody>
        ) : (
          <TableBody style={{ backgroundColor: "white" }}>
            {stores &&
              stores.map((store) => (
                <StoreTableRow key={store.storeId} store={store} />
              ))}
          </TableBody>
        )}
      </Table>
    </>
  );
};
