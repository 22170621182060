import { useQuery } from "@tanstack/react-query";
import { fetchUsers } from "../services/users.service";

export const useFetchUsers = (countryId: string, storeId: string, roleId: string) => {
  return useQuery({
    queryKey: ["users", countryId, storeId, roleId],
    queryFn: () => fetchUsers(countryId, storeId, roleId),
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
