import { ItemsInfo } from "../components/roomsettings/types/Articles.types";
import { correctArticleNumberLength } from "../helpers/helpers";
import { tasksServiceBaseUrl } from "./shared.service";
import { makeServiceHeaders } from "./shared.service";

const BASE_URL = `${tasksServiceBaseUrl()}/items`;

export const fetchItemsInfo = async (articleNumbers: string): Promise<ItemsInfo> => {
  if (articleNumbers) {
    const correctedArticleNumbers = articleNumbers
      .split(",")
      .map((articleNumber) => correctArticleNumberLength(articleNumber.trim()))
      .join(",");

    try {
      const url = `${BASE_URL}/items-info?itemNos=${correctedArticleNumbers}`;
      const response = await fetch(url, {
        method: "GET",
        headers: await makeServiceHeaders(),
      });

      if (!response.ok) {
        throw new Error("Response was not ok.");
      }

      return (await response.json()) as ItemsInfo;
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }
  return [];
};
