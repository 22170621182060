import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addRoom } from "../../services/rooms.service";

const useCreateRoom = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addRoom,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["rooms"] });
    },
  });
};

export default useCreateRoom;
