import React from "react";
import { Route, Routes } from "react-router-dom";
import { UsersPage } from "./users/organisms/UsersPage";
import { TeamsTable } from "./teams/organisms/TeamsTable";
import { AllStoresTable } from "./stores/organisms/AllStoresTable";
import { StoreTable } from "./stores/organisms/StoreTable";
import { StoreContactsTable } from "./storeContacts/organisms/StoreContactsTable";
import { CoworkerNoAccessPage } from "./CoworkerNoAccessPage";

interface TabRouterProps {
  isAdmin: boolean;
}

export const TabRouter = ({ isAdmin }: TabRouterProps) => {
  return (
    <Routes>
      {isAdmin && (
        <>
          <Route path="/" element={<AllStoresTable />} />
          <Route path="/users" element={<UsersPage />} />
          {/* Team routes */}
          <Route path="/teams" element={<TeamsTable />} />
          {/* Store routes */}
          <Route path="/stores" element={<AllStoresTable />} />
          <Route path="/stores/:storeNumber" element={<StoreTable />} />
          <Route path="/storecontacts" element={<StoreContactsTable />} />
        </>
      )}
      {!isAdmin && (
        <>
          <Route path="/" element={<CoworkerNoAccessPage />} />
        </>
      )}
    </Routes>
  );
};
