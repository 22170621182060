import React from "react";
import { TableHeader } from "@ingka/table";
import { UpdateArticlesButton } from "./UpdateArticlesButton";

interface RoomSettingsArticlesTableHeaderProps {
  handleUpdateArticles: () => Promise<void>;
}

export const RoomSettingsArticlesTableHeader = ({ handleUpdateArticles }: RoomSettingsArticlesTableHeaderProps) => {
  return (
    <TableHeader sticky>
      <tr>
        <th>
          <b>ARTICLE</b>
        </th>
        <th>
          <b>NUMBER OF ARTICLES</b>
        </th>
        <th>
          <b>HFB</b>
        </th>
        <th>
          <b>DATE STAMP</b>
        </th>
        <th>
          <b>SUPPLIER NUMBER</b>
        </th>
        <th>
          <b>DATE ADDED</b>
        </th>
        <th>
          <b>ACTIVE</b>
        </th>
        <th>
          <UpdateArticlesButton updateArticlesButtonPressed={handleUpdateArticles} />
        </th>
      </tr>
    </TableHeader>
  );
};
