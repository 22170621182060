import { useQuery } from "@tanstack/react-query";
import { getSalesStopData } from "../../services/articles.service";

export const useSalesStopData = (storeId: string, articleNumbers: string) => {
  return useQuery({
    queryKey: ["salesStop", storeId, articleNumbers],
    queryFn: () => getSalesStopData(storeId, articleNumbers),
    enabled: !!storeId && !!articleNumbers,
    gcTime: 1000 * 60 * 15, // 15 minutes cache time
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });
};
