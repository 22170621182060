import React from "react";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Loader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 50px;
  height: 50px;

  div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
  }

  div:first-child {
    animation: bounce 1s 0.2s infinite;
  }

  div:last-child {
    animation: bounce 1s 0.4s infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
`;

export const BouncingBallsLoader = () => {
  return (
    <>
      <LoaderWrapper>
        <Loader>
          <div />
          <div />
        </Loader>
      </LoaderWrapper>
    </>
  );
};
