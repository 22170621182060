import React from "react";
import Image from "@ingka/image";
import InputField from "@ingka/input-field";
import Switch from "@ingka/switch";
import FormField from "@ingka/form-field";
import { EdsData, SalesStopData, Article } from "../types/Articles.types";
import { formatFullIdWithDots } from "../../../utils/product.formatter";
import { deleteArticle, updateArticle } from "../services/articles.service";
import ProductIdentifier from "@ingka/product-identifier";
import ItemName from "../atoms/ItemName";
import { CenteredFlexBox } from "../../../config/styles";
import useRoom from "../hooks/rooms/useRoom";
import { DeleteArticleModal } from "./DeleteArticleModal";
import { ReplaceArticleModal } from "./ReplaceArticleModal";
import {
  BackupProductMessage,
  EDSMessage,
  FlexColumn,
  FlexRowCenter,
  MainProductMessage,
  SalesStopMessage,
  VerticalAlignedTableCell,
} from "../../../styles/styles";
import {
  checkDateStamp,
  checkEDS,
  checkSalesStop,
  formatEDSDate,
  hasOngoingTasks,
  isBackupForProduct,
  isMainArticle,
} from "./ArticleRow.helper";
import { UpdateArticleButton } from "../molecules/UpdateArticleButton";
import { TaskLinks } from "../types/taskLinks.types";
import { getRegion } from "../../../services/shared.service";
import { GeneralQuantityStepper } from "../atoms/GeneralQuantityStepper";
import { useQueryClient } from "@tanstack/react-query";

interface ArticleRowProps {
  article: Article;
  articleUpdated: (articleId: string, isDelete: boolean, errorMessage: string | undefined) => Promise<void>;
  updateArticleButtonPressed: (article: Article) => void;
  edsData: EdsData | null;
  salesStopData: SalesStopData | null;
  taskLinks: TaskLinks;
}

export const ArticleRow = ({
  article,
  articleUpdated,
  updateArticleButtonPressed,
  edsData,
  salesStopData,
  taskLinks,
}: ArticleRowProps) => {
  const [currentDateStamp, setCurrentDateStamp] = React.useState<string>("");
  const [currentSupplierNumber, setCurrentSupplierNumber] = React.useState<string>("");

  const { data: room } = useRoom(article.roomId);
  const queryClient = useQueryClient();

  const handleDelete = async (articleId: string) => {
    if (taskLinks && hasOngoingTasks(taskLinks, article.productArticleId)) return;

    try {
      await deleteArticle(articleId);
      await articleUpdated(articleId, true, undefined);
      await queryClient.invalidateQueries({ queryKey: ["roomArticles", { id: article.roomId }] });
    } catch (error) {
      console.log(error);
      await articleUpdated(articleId, false, "Could not delete article. Please try again later.");
    }
  };

  const updateArticleWithErrorHandler = (
    articleId: string,
    update: Partial<Article>,
    errorMessage: string
  ): boolean => {
    let success = false;
    updateArticle(articleId, update)
      .then(() => {
        articleUpdated(articleId, false, undefined).catch(console.error);
        success = true;
      })
      .catch(() => articleUpdated(article.id, false, errorMessage).catch(console.error));
    return success;
  };

  return (
    <tr key={`row_${article.id}`} id={`row_${article.id}`}>
      {/* Article */}
      <VerticalAlignedTableCell>
        <FlexColumn>
          <FlexRowCenter>
            {room &&
              isMainArticle(article.id, room.room.main_article_id_1 ?? "", room.room.main_article_id_2 ?? "") && (
                <MainProductMessage message={"Main product".toUpperCase()} />
              )}
            {room &&
              isBackupForProduct(
                article.id,
                room.room.backup_article_id_1 ?? "",
                room.room.backup_article_id_2 ?? ""
              ) && <BackupProductMessage message={"Backup product".toUpperCase()} />}
            {salesStopData && checkSalesStop(salesStopData) && <SalesStopMessage message={"SALES STOP"} />}
            {edsData && checkEDS(edsData) && (
              <EDSMessage message={formatEDSDate(edsData.validToDateTime || "", getRegion())} />
            )}
          </FlexRowCenter>
          <FlexRowCenter>
            {article.imageSmall ? (
              <Image src={article.imageSmall} style={{ width: "72px" }} alt={article.itemName} />
            ) : (
              <div style={{ width: "72px", height: "72px", background: "var(--grey200)", flexShrink: 0 }} />
            )}
            <CenteredFlexBox style={{ gap: "4px", alignItems: "start" }}>
              <ProductIdentifier value={formatFullIdWithDots(article.productArticleId)} subtle />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ItemName>{article.itemName}</ItemName>
                <p>{article.itemType}</p>
              </div>
            </CenteredFlexBox>
          </FlexRowCenter>
        </FlexColumn>
      </VerticalAlignedTableCell>

      {/* Number of articles */}
      <VerticalAlignedTableCell>
        <GeneralQuantityStepper
          key={`nbr_articles_${article.id}`}
          id={`nbr_articles_${article.id}`}
          small={true}
          value={article.nbrArticles}
          ariaDescriptionId={`nbr_articles_${article.id}`}
          ariaDescription={""}
          onQuantityChange={(newNbrArticles: number | string) => {
            const parsedNbrArticles =
              typeof newNbrArticles === "number" ? newNbrArticles : parseInt(newNbrArticles, 10);
            if (article.nbrArticles === parsedNbrArticles) return;
            updateArticleWithErrorHandler(
              article.id,
              { ...article, nbrArticles: parsedNbrArticles },
              "Could not update article. Please try again later."
            );
          }}
        />
      </VerticalAlignedTableCell>

      {/* HFB */}
      <VerticalAlignedTableCell>{article.hfb}</VerticalAlignedTableCell>

      {/* Date stamp */}
      <VerticalAlignedTableCell>
        <FormField
          shouldValidate={!checkDateStamp(currentDateStamp) && currentDateStamp.length > 0}
          fieldHelper={{
            msg: "YYWW",
            id: `date_stamp_${article.id}`,
          }}
          validation={{
            msg: "Invalid date stamp",
            id: `date_stamp_${article.id}`,
          }}
        >
          <InputField
            id={`date_stamp_${article.id}`}
            type="number"
            maxLength={4}
            defaultValue={article.dateStamp}
            onChange={(event) => {
              if (!!article && checkDateStamp(event.target.value)) {
                const success = updateArticleWithErrorHandler(
                  article.id,
                  { dateStamp: event.target.value },
                  "Could not update date stamp. Please try again later."
                );
                if (success) setCurrentDateStamp(event.target.value);
              }
            }}
          />
        </FormField>
      </VerticalAlignedTableCell>

      {/* Supplier number */}
      <VerticalAlignedTableCell>
        <FormField
          shouldValidate={currentSupplierNumber.length !== 5 && currentSupplierNumber.length > 0}
          fieldHelper={{
            msg: "XXXXX",
            id: `supplier_number_${article.id}`,
          }}
          validation={{
            msg: "Invalid supplier number",
            id: `supplier_number_${article.id}`,
          }}
        >
          <InputField
            id={`supplier_number_${article.id}`}
            type="number"
            defaultValue={article.supplierNumber}
            maxLength={5}
            onChange={(event) => {
              if (event.target.value.length === 5) {
                const success = updateArticleWithErrorHandler(
                  article.id,
                  { supplierNumber: event.target.value },
                  "Could not update supplier number. Please try again later."
                );
                if (success) setCurrentSupplierNumber(event.target.value);
              }
            }}
          />
        </FormField>
      </VerticalAlignedTableCell>

      {/* Created at */}
      <VerticalAlignedTableCell>
        <p>{new Date(article.createdAt).toLocaleDateString()}</p>
      </VerticalAlignedTableCell>

      {/* Active */}
      <VerticalAlignedTableCell>
        <Switch
          key={`active_${article.id}`}
          id={`active_${article.id}`}
          value={article.productArticleId}
          checked={article.active === "ACTIVE"}
          onChange={() => {
            const active = article.active === "ACTIVE" ? "INACTIVE" : "ACTIVE";
            updateArticleWithErrorHandler(
              article.id,
              { active },
              "Could not update article status. Please try again later."
            );
          }}
        />
      </VerticalAlignedTableCell>

      {/* Buttons */}
      <VerticalAlignedTableCell>
        <ReplaceArticleModal taskLinks={taskLinks ?? []} article={article} />
        <DeleteArticleModal taskLinks={taskLinks ?? []} article={article} onDelete={() => handleDelete(article.id)} />
        <UpdateArticleButton article={article} updateArticleButtonPressed={updateArticleButtonPressed} />
      </VerticalAlignedTableCell>
    </tr>
  );
};
