import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteUser } from "../services/users.service";

export const useDeleteUser = (fixaUid: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteUser(fixaUid),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
};
