import dayjs from "dayjs";
import { Articles, EdsData, SalesStopData } from "../types/Articles.types";
import { TaskLinks } from "../types/taskLinks.types";
import { Region } from "../../../utils/region.helpers";

export const isThisYear = (timestamp: string) => {
  return dayjs(timestamp).year() === dayjs().year();
};

export function formatEDSDate(timestamp: string, region: Region) {
  const date = dayjs(timestamp);

  if (!date.isValid()) {
    return "Invalid Date";
  }

  if (isThisYear(timestamp)) {
    return region === "na" ? `EDS ${date.format("DD/MM")}` : `EDS ${date.format("MM/DD")}`;
  } else {
    return region === "na" ? `EDS ${date.format("YY/DD/MM")}` : `EDS ${date.format("YY/MM/DD")}`;
  }
}

export const checkDateStamp = (dateStamp: string) => {
  if (dateStamp.length !== 4) {
    return;
  }

  const year = parseInt(dateStamp.substring(0, 2));
  const week = parseInt(dateStamp.substring(2, 4)).toString().padStart(2, "0");
  const currentYear = new Date().getFullYear() % 100;

  if (year <= currentYear && week <= "53" && week >= "01") {
    return true;
  }

  return false;
};

export const checkSalesStop = (salesStop: SalesStopData) => {
  if (salesStop.reason) return true;
  return false;
};

export const checkEDS = (edsData: EdsData) => {
  if (edsData.validFromDateTime) return true;
  return false;
};

export const isMainArticle = (
  articleId: string,
  roomsMainArticleId: string,
  roomsSecondaryMainArticleId: string
): boolean => {
  return articleId === roomsMainArticleId || articleId === roomsSecondaryMainArticleId;
};

export const isBackupForProduct = (
  articleId: string,
  roomsBackupArticleId: string,
  roomsSecondaryBackupArticleId: string
): boolean => {
  return articleId === roomsBackupArticleId || articleId === roomsSecondaryBackupArticleId;
};

export const sortArticles = (
  articles: Articles,
  roomsMainArticleId: string,
  roomsSecondaryMainArticleId: string,
  roomsBackupArticleId: string,
  roomsSecondaryBackupArticleId: string
): Articles => {
  return articles.sort((a, b) => {
    if (a.id === roomsMainArticleId) return -1;
    if (b.id === roomsMainArticleId) return 1;
    if (a.id === roomsSecondaryMainArticleId) return -1;
    if (b.id === roomsSecondaryMainArticleId) return 1;
    if (a.id === roomsBackupArticleId) return -1;
    if (b.id === roomsBackupArticleId) return 1;
    if (a.id === roomsSecondaryBackupArticleId) return -1;
    if (b.id === roomsSecondaryBackupArticleId) return 1;

    const nameComparison = a.itemName.localeCompare(b.itemName);
    if (nameComparison !== 0) return nameComparison;

    return a.id.localeCompare(b.id);
  });
};

export const hasOngoingTasks = (taskLinks: TaskLinks, productArticleId: string) => {
  return taskLinks.some((taskLink) => taskLink.productArticleId === productArticleId);
};
