import { coreServiceBaseUrl, makeServiceHeaders } from "../../../services/shared.service";
const COUNTRIES_BASE_URL = `${coreServiceBaseUrl()}/countries`;

type Country = {
  isoCode: string;
  name: string;
};

export const getAllCountries = async () => {
  try {
    const response = await fetch(COUNTRIES_BASE_URL, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as Country[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};
