import React from "react";
import styles from "./load-more.module.css";
import Button from "@ingka/button";

interface LoadMoreProps {
  handleClick: () => void;
  isFetching?: boolean;
}

export const LoadMore = ({ handleClick, isFetching }: LoadMoreProps) => {
  return (
    <div className={styles["load-more"]}>
      <div className={styles["content-wrapper"]}>
        <Button onClick={handleClick} text="Load More" type="secondary" small fluid loading={isFetching} />
      </div>
    </div>
  );
};
