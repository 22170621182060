import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addRoomImages } from "../../services/images.service";

export const useUploadImage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addRoomImages,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["rooms"] }).catch((error: unknown) => {
        console.error("Error invalidating queries:", error);
      });
      return data;
    },
  });
};
