import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteStoreContact } from "../services/storeContacts.service";

export const useDeleteStoreContact = (storeContactId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteStoreContact(storeContactId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["store-contacts"] });
    },
  });
};
