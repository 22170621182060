import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  padding: 20px 20px 40px 20px;
`;

const HeaderRow = styled.div`
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  .right > * {
    margin-left: 10px;
  }
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
`;

const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  .left,
  .right {
    align-items: center;
    display: flex;
    gap: 10px;

    & > * {
      flex-grow: 0;
    }
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.span`
  margin: 0 5px;
`;

type TableToolsHeaderProps = {
  setShowTeamUsersTable?: React.Dispatch<React.SetStateAction<boolean>>;
  showTeamUsersTable?: boolean;
  selectedTeam?: { id: string; name: string };
  tableTitle: string;
  tableSubtitle?: string;
  headerControls?: React.ReactNode;
  filtersLeft?: React.ReactNode;
  filtersRight?: React.ReactNode;
};

export const TableToolsHeader = ({
  setShowTeamUsersTable,
  showTeamUsersTable,
  selectedTeam,
  tableTitle,
  tableSubtitle,
  headerControls,
  filtersLeft,
  filtersRight,
}: TableToolsHeaderProps) => {
  return (
    <Container>
      <HeaderRow>
        <div className="left">
          <HeaderContainer>
            <u>
              <h1 onClick={() => setShowTeamUsersTable && setShowTeamUsersTable(false)}>{tableTitle}</h1>
            </u>
            {showTeamUsersTable && (
              <>
                <Separator>/</Separator>
                <h4 style={{ marginTop: "5px" }}>{selectedTeam && selectedTeam.name}</h4>
              </>
            )}
          </HeaderContainer>

          <Subtitle>{tableSubtitle}</Subtitle>
        </div>
        <div className="right">{headerControls}</div>
      </HeaderRow>
      <FiltersRow>
        <div className="left">{filtersLeft}</div>
        <div className="right">{filtersRight}</div>
      </FiltersRow>
    </Container>
  );
};
