import { useQuery } from "@tanstack/react-query";
import { getAllStores } from "../services/stores.service";

const useStores = () => {
  return useQuery({
    queryKey: ["stores"],
    queryFn: getAllStores,
    staleTime: 7 * 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useStores;
