import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import Select, { Option } from "@ingka/select";
import { ModalOption } from "../../../modals/SkapaSheetModal";
import { ModalItemWrapper } from "../../../config/styles";
import { UserRolesArray } from "../../../data/constants";
import useStoresByCountryId from "../../stores/hooks/useStoresByCountryId";
import { CreateUserType, FormattedType, OptionType } from "../types/users.types";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import useTeams from "../../teams/hooks/useTeams";
import { graphSearchUsers } from "../services/searchUsers";
import { Team, Teams } from "../../teams/types/Teams.types";
import { useDeleteUser } from "../hooks/useDeleteUser";
import getGraphToken from "../../../services/getGraphToken";
import useStores from "../../stores/hooks/useStores";
import Button from "@ingka/button";
import { useCreateUser } from "../hooks/useCreateUser";
import useUpdateUser from "../hooks/useUpdateUser";
import useCountries from "../../stores/hooks/useCountries";
import { getAccessibleRoles } from "../../../data/constants";
import { ConfirmCancelModal } from "../../../modals/ConfirmCancelModal";
import { UserRole } from "../../../types/roles.types";
import { SkapaToast } from "../../SkapaToast";

export const Message = styled.p<{ color: string }>`
  width: fit-content;
  padding: 16px 0 0 0;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const AllStoresByCountry = ({ country }: { country?: string | undefined }) => {
  const { user } = useLoggedInUser();
  if (!user) {
    return <></>;
  }

  const countryId = country || user.countryId;
  const { data: stores } = useStoresByCountryId(countryId);
  stores?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  return (
    <>
      {stores?.map((store) => (
        <Option
          selected={store.storeId === user.storeId}
          key={store.storeId}
          id={store.storeId}
          name={`${store.storeId} ${store.name}`}
          value={store.storeId}
        />
      ))}
    </>
  );
};

export const AllCountries = () => {
  const { user } = useLoggedInUser();
  if (!user) {
    return <></>;
  }

  const countries = useCountries();
  countries.data?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  return (
    <>
      {countries.data?.map(({ isoCode, name }) => (
        <Option key={isoCode} id={name} name={name} value={isoCode} selected={isoCode === user.countryId} />
      ))}
    </>
  );
};

export const AllRoles = () => {
  return (
    <>
      {UserRolesArray.map((role) => (
        <Option key={role.value} id={role.value} name={role.name} value={role.value} />
      ))}
    </>
  );
};

export const FilteredRoles = () => {
  const { user } = useLoggedInUser();

  if (!user) {
    return <></>;
  }

  const accessibleRoles = getAccessibleRoles(user.roleId);

  return (
    <>
      {UserRolesArray.map((role) => (
        <Option
          key={role.value}
          id={role.value}
          name={role.name}
          value={role.value}
          disabled={!accessibleRoles.includes(role.value)}
        />
      ))}
    </>
  );
};

export const TeamsInStore = ({ teams }: { teams: Teams }) => {
  teams?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  return <>{teams?.map((team: Team) => <Option key={team.id} id={team.id} name={team.name} value={team.id} />)}</>;
};

interface UserModalChildrenProps {
  user: CreateUserType;
  setUserHandler: React.Dispatch<React.SetStateAction<CreateUserType | undefined>>;
  modalType: ModalOption;
  country?: string;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserModalChildren = ({
  user,
  setUserHandler,
  modalType,
  country,
  setSuccessMessage,
  setModalVisible,
}: UserModalChildrenProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: teams, isLoading: isTeamsLoading } = useTeams();
  const { data: stores, isLoading: isStoresLoading } = useStores();
  const token = getGraphToken();
  // First input field from the modal should user data from cds.
  const [selectedStore, setSelectedStore] = useState<string>(user?.storeId || "");
  const { user: loggedInUser } = useLoggedInUser();
  const editableUserRoles = [loggedInUser?.roleId ?? "", ...getAccessibleRoles(loggedInUser?.roleId ?? "COWORKER")];

  const defaultTeamId = user.teamId; //teams?.find((team) => team.id === user.teamId)?.name;

  const [selectedTeam, setSelectedTeam] = useState<string>(defaultTeamId || "");
  const [selectedRole, setSelectedRole] = useState<string>(
    modalType === ModalOption.ADD ? "COWORKER" : user?.roleId || "COWORKER"
  );
  const [selectedCountry, setSelectedCountry] = useState<string>(country || "");
  const [teamsInStore, setTeamsInStore] = useState<Teams>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    mutateAsync: updateUserMutation,
    isPending: isUpdatingUserPending,
    isError: isUpdateUserError,
    error: updateErrorMessage,
  } = useUpdateUser();
  const {
    mutateAsync: createUserMutation,
    isPending: isCreateUserPending,
    isError: isCreateUserError,
    error: createErrorMessage,
  } = useCreateUser(user);
  React.useEffect(() => {
    if (!isTeamsLoading) {
      const defaultTeamList = teams?.filter((team) => team.storeId === user.storeId).map((team) => team) || [];
      setTeamsInStore(defaultTeamList);
    }
  }, [isTeamsLoading, teams]);

  const debounceTime = 750;

  const onStoreSelectChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeEvent.target !== null && setUserHandler && !isStoresLoading) {
      const value = changeEvent.target.value;
      const store = stores?.find((store) => store.storeId === changeEvent.target.value);
      setSelectedStore(value);
      setUserHandler({
        ...user,
        storeId: store?.storeId ?? "",
      });
      const filteredTeams = teams?.filter((team) => team.storeId === value).map((team) => team) || [];
      setTeamsInStore(filteredTeams);
    }
  };

  const onTeamSelectChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeEvent.target !== null && setUserHandler) {
      const teamId = teams?.find((team) => team.id === changeEvent.target.value)?.id;

      setSelectedTeam(changeEvent.target.value);
      setUserHandler({
        ...user,
        teamId: teamId ?? "",
      });
    }
  };

  const onRoleSelectChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeEvent.target !== null) {
      const roleId = UserRolesArray.find((userRole) => userRole.value === changeEvent.target.value)?.value;

      if (roleId) {
        setSelectedRole(roleId);
        setUserHandler({
          ...user,
          roleId: roleId ?? "",
        });
      }
    }
  };

  const onCountrySelectChange = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    if (changeEvent.target !== null && setUserHandler) {
      const countryId = changeEvent.target.value;
      setUserHandler({
        ...user,
        countryId: countryId,
      });
      setSelectedCountry(countryId);
      setSelectedStore("");
      setSelectedTeam("");
    }
  };

  function handleSearch(query: string | number | boolean, callback: (result: FormattedType[]) => void) {
    if (typeof query === "string" && query.length < 3) {
      return;
    }

    setTimeout(
      (query, callback) => {
        setIsLoading(true);
        graphSearchUsers(query, token)
          .then((result) => {
            const formatted: FormattedType[] = (result as OptionType[]).map((option: OptionType) => ({
              value: option.id,
              label: option.mail,
              name: option.displayName,
            }));
            callback(formatted);
          })
          .catch((err) => {
            console.log("errorSearchUsers", err);
          });
      },
      debounceTime,
      query,
      callback
    );
  }

  const noOptionsMessage = React.useCallback(() => {
    if (isLoading) {
      return "Loading...";
    }
    return "No results found";
  }, [isLoading]);

  const deleteUserMutation = useDeleteUser(user.fixaUid);
  const isEditModal = modalType === ModalOption.EDIT;

  const isEditOptionInvalid = (user: CreateUserType) => {
    console.log(user.countryId, user?.roleId, user?.teamId);
    return user?.countryId === "" || !user?.roleId || user?.teamId === "";
  };
  const isAddOptionInvalid = (user: CreateUserType) => {
    return user?.storeId === "" || !user?.roleId || user?.teamId === "";
  };
  const handleSubmit = async () => {
    if (isEditModal ? isEditOptionInvalid(user) : isAddOptionInvalid(user)) {
      return;
    }
    try {
      if (isEditModal) {
        await updateUserMutation({
          fixaUid: user.fixaUid,
          roleId: user.roleId,
          storeId: user.storeId,
          teamId: user.teamId,
          status: user.status,
          countryId: user.countryId,
        });
        setSuccessMessage(`User: ${user.fullName || user.givenName + " " + user.familyName}, edited successfully`);
        if (user.fullName) {
          setModalVisible(false);
        } else {
          setModalVisible(true);
        }
      } else {
        await createUserMutation();
        setSuccessMessage(`User: ${user.emails && user?.emails[0]} created successfully`);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDelete = async () => {
    await deleteUserMutation.mutateAsync();
    setSuccessMessage(`User: ${user.fullName}, deleted successfully`);
    setModalVisible(false);
  };

  return (
    <>
      {isEditModal && !editableUserRoles.includes(user.roleId) ? (
        <div>You do not have access to edit this user.</div>
      ) : (
        <ModalItemWrapper>
          {!isEditModal && (
            <AsyncSelect
              aria-label="Enter the co-worker's name"
              isMulti={true}
              cacheOptions
              loadOptions={handleSearch}
              noOptionsMessage={noOptionsMessage}
              onChange={(event) =>
                setUserHandler?.({
                  ...user,
                  emails: event.map((item) => item.label) || [],
                  roleId: selectedRole as UserRole, //set the role to selectedRole (coworker) in case there are no changes in the role select
                })
              }
            />
          )}
          {loggedInUser?.roleId === "SUPERUSER" && (
            <Select
              id={"country"}
              hintText={"Choose a country"}
              label={"Country"}
              children={<AllCountries />}
              value={user.countryId}
              onChange={onCountrySelectChange}
            />
          )}
          <Select
            id={"role"}
            hintText={""}
            label={"Role"}
            children={<FilteredRoles />}
            value={selectedRole}
            onChange={onRoleSelectChange}
          />
          <Select
            id={"store"}
            hintText={"Choose a store"}
            label={"Store"}
            children={<AllStoresByCountry country={selectedCountry} />}
            value={selectedStore}
            onChange={onStoreSelectChange}
          />
          <Select
            id={"team"}
            hintText={"Choose a team"}
            label={"Team"}
            children={<TeamsInStore teams={teamsInStore} />}
            value={selectedTeam}
            onChange={onTeamSelectChange}
          />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {isEditModal && user.fixaUid !== loggedInUser?.fixaUid && (
              <div style={{ marginTop: "auto" }}>
                <Button
                  small
                  type="danger"
                  fluid={false}
                  style={{ backgroundColor: "#e00751", color: "#fff" }}
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  children={"Delete User"}
                />
              </div>
            )}
          </div>
          <ConfirmCancelModal
            header="Confirm Delete"
            title={`Are you sure you want to delete user: ${user.fullName}?`}
            message="This action cannot be undone."
            isVisible={isModalVisible}
            onOK={handleDelete}
            onCancel={handleCancel}
          />
          {isUpdateUserError && <Message color="red">{updateErrorMessage.message}</Message>}
          {isCreateUserError && <Message color="red">{createErrorMessage.message}</Message>}
          <Button
            type="primary"
            fluid={true}
            text={modalType === ModalOption.EDIT ? "Edit User" : "Add User"}
            onClick={handleSubmit}
            loading={isUpdatingUserPending || isCreateUserPending}
          />
        </ModalItemWrapper>
      )}
    </>
  );
};

export const EditLoggedInUser = ({ user, modalVisible }: { user: CreateUserType; modalVisible: boolean }) => {
  const [editedUser, setEditedUser] = useState<CreateUserType | undefined>(user);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isModalVisible, setModalVisible] = useState(modalVisible || false);

  return (
    <>
      {editedUser && isModalVisible && (
        <UserModalChildren
          user={editedUser}
          setUserHandler={setEditedUser}
          modalType={ModalOption.EDIT}
          setModalVisible={setModalVisible}
          setSuccessMessage={(message: string | ((prevState: string) => string)) => {
            setToastMessage(message);
            setIsToastOpen(true);
          }}
        />
      )}
      {isToastOpen && (
        <SkapaToast text={toastMessage} isOpen={isToastOpen} onCloseRequest={() => setIsToastOpen(false)} />
      )}
    </>
  );
};
