import { Articles } from "../types/Articles.types";

export const getArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? article.itemName : "";
};

export const getFullArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? `${article.productArticleId}: ${article.itemName}, ${article.itemType}` : "";
};
