import { useQuery } from "@tanstack/react-query";
import { getAllStores } from "../services/stores.service";

const useStores = () => {
  return useQuery({
    queryKey: ["stores"],
    queryFn: getAllStores,
  });
};

export default useStores;
