export const USER_ROLES = ["MANAGER", "COWORKER", "STOREOWNER", "COUNTRY_SUPERUSER", "SUPERUSER"] as const;
export const SUPERUSER_ROLES = ["SUPERUSER", "COUNTRY_SUPERUSER", "STOREOWNER"] as const;
export const GLOBAL_SUPERUSER_ROLES = ["SUPERUSER"] as const;
export const COUNTRY_SUPERUSER_ROLES = ["COUNTRY_SUPERUSER"] as const;
export const STOREOWNER = ["STOREOWNER"] as const;

export type UserRole = "MANAGER" | "COWORKER" | "STOREOWNER" | "COUNTRY_SUPERUSER" | "SUPERUSER";
export type SuperUserRole = "SUPERUSER" | "COUNTRY_SUPERUSER" | "STOREOWNER";
export type GlobalSuperUserRole = "SUPERUSER";
export type CountrySuperUserRole = "COUNTRY_SUPERUSER";
export type StoreOwnerRole = "STOREOWNER";
