import { useQuery } from "@tanstack/react-query";
import { getArticlesByRoomId } from "../../services/rooms.service";

const useArticlesByRoomId = (roomId: string | undefined) => {
  return useQuery({
    queryKey: ["roomArticles", { id: roomId }],
    queryFn: () => getArticlesByRoomId(roomId),
  });
};

export default useArticlesByRoomId;
