import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStoreStatus } from "../services/stores.service";

const useUpdateStoreStatus = (storeId: string, status: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => updateStoreStatus(storeId, status),
    onSuccess: (updatedStore) => {
      queryClient.setQueryData(["store", storeId], updatedStore);
      return queryClient.invalidateQueries({ queryKey: ["stores"] });
    },
  });
};

export default useUpdateStoreStatus;
