import React from "react";
import InlineMessage from "@ingka/inline-message";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/inline-message/dist/style.css";
import "@ingka/focus/dist/style.css";

interface Props {
  title?: string;
  body: string;
  variant: "positive" | "cautionary" | "negative";
  subtle?: boolean;
}

export const InlineMessageBox = (props: Props) => {
  return <InlineMessage {...props} />;
};
