import React, { useRef, useState } from "react";
import plus from "@ingka/ssr-icon/paths/plus-small";
import Button from "@ingka/button";

interface FileUploadButtonProps {
  handleFile: (file: File) => Promise<void>;
}

export const FileUploadButton = ({ handleFile }: FileUploadButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!event.target.files) {
      return;
    }
    const files = Array.from(event.target.files);
    try {
      for (const file of files) {
        await handleFile(file);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button loading={isLoading} type={"secondary"} ssrIcon={plus} onClick={handleButtonClick} text={"Add photo"} />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={(event) => {
          handleChange(event)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        }}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
};
