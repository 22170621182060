import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UpdateRoomSettingForm } from "./UpdateRoomSettingForm";
import { AddArticlesForm } from "./AddArticlesForm";
import useRoom from "../hooks/rooms/useRoom";
import { RoomSettingsArticlesTable } from "./RoomSettingsArticlesTable";
import useArticlesByRoomId from "../hooks/articles/useArticlesByRoomId";
import { Articles } from "../types/Articles.types";
import { useQueryClient } from "@tanstack/react-query";
import useUpdateRoom from "../hooks/rooms/useUpdateRoom";
import { PartialRoom } from "../types/rooms.types";
import { SearchBar } from "../molecules/SearchBar";
import { MessageModal } from "../../../modals/MessageModal";
import { TableToolsHeader } from "../../layout/TableToolsHeader";
import useGroupsByRoomId from "../hooks/groups/useGroupsByRoomId";
import { GroupedArticlesList } from "./GroupedArticlesList";
import { updateUnknownArticles } from "../helpers/updateArticles.helper";

export const RoomSettingByRoomId = () => {
  const { storeNumber } = useParams();
  const { roomId } = useParams();
  const queryClient = useQueryClient();
  const updateRoomMutation = useUpdateRoom(roomId || "", storeNumber || "");

  const { data: roomObj } = useRoom(roomId);
  const { data: loadedArticles, refetch } = useArticlesByRoomId(roomId);
  const { data: groups } = useGroupsByRoomId(roomId || "");

  const [articles, setArticles] = useState<Articles | undefined>([]);
  const [searchResults, setSearchResults] = useState<Articles | undefined>([]);
  const [showModalMessage, setShowModalMessage] = useState<string | undefined>();

  const handleSearchResults = (results: Articles) => {
    setSearchResults(results);
  };

  useEffect(() => {
    setSearchResults(articles);
  }, [articles]);

  useEffect(() => {
    setArticles(loadedArticles);
  }, [loadedArticles]);

  const handleArticleChange = async (articleId: string, isDelete: boolean, errorMessage: string | undefined) => {
    if (errorMessage) {
      setShowModalMessage(errorMessage);
      return;
    }

    if (isDelete) {
      const article = articles?.find((article) => article.id === articleId);
      if (roomObj?.room.main_article_id_1 === article?.id) {
        await updateRoomMutation.mutateAsync({
          ...roomObj?.room,
          main_article_id_1: "",
          main_article_name_1: "",
        } as PartialRoom);
      }
      if (roomObj?.room.main_article_id_2 === article?.id) {
        await updateRoomMutation.mutateAsync({
          ...roomObj?.room,
          main_article_id_2: "",
          main_article_name_2: "",
        } as PartialRoom);
      }
      if (roomObj?.room.backup_article_id_1 === article?.id) {
        await updateRoomMutation.mutateAsync({
          ...roomObj?.room,
          backup_article_id_1: "",
          backup_article_name_1: "",
        });
      }
      if (roomObj?.room.backup_article_id_2 === article?.id) {
        await updateRoomMutation.mutateAsync({
          ...roomObj?.room,
          backup_article_id_2: "",
          backup_article_name_2: "",
        });
      }
    }
    await queryClient.invalidateQueries({ queryKey: ["roomArticles", { id: roomId }] });
    setArticles(articles?.filter((article) => !isDelete || article.id !== articleId));
  };

  const handleArticlesAdded = async (isSuccess: boolean) => {
    if (isSuccess) {
      await queryClient.invalidateQueries({ queryKey: ["roomArticles", { id: roomId }] });
      await refetch();
    } else {
      setShowModalMessage("Failed to add articles. Please try again later.");
    }
  };

  const handleUpdateArticles = async () => {
    await updateUnknownArticles(articles || []);
    await handleArticlesAdded(true);
  };

  return (
    <>
      <TableToolsHeader
        setShowTeamUsersTable={() => {}}
        showTeamUsersTable={false}
        selectedTeam={{ id: "", name: "" }}
        tableTitle={`Room settings for ${roomObj?.room.name}`}
        headerControls={
          <>
            <AddArticlesForm roomId={roomId || ""} articlesAdded={handleArticlesAdded} />
            <UpdateRoomSettingForm roomId={roomId ?? ""} />
          </>
        }
        filtersRight={
          <SearchBar
            id="article-search"
            items={articles}
            searchProps={["itemName", "itemType", "itemColor"]}
            onSearchResults={handleSearchResults}
          />
        }
      />
      {!!showModalMessage && (
        <MessageModal message={showModalMessage} closeModal={() => setShowModalMessage(undefined)} />
      )}
      <RoomSettingsArticlesTable
        articles={searchResults || []}
        articleUpdated={handleArticleChange}
        handleUpdateArticles={handleUpdateArticles}
        groupId={""}
        showHeaders={true}
      />
      {!!groups &&
        groups.map((group) => {
          const filteredArticles = searchResults?.filter((article) => article.groupId === group.id) || [];
          return <GroupedArticlesList articleList={filteredArticles} group={group} />;
        })}
    </>
  );
};
