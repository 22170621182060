import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addRoomImageToCloud } from "../../services/images.service";

export const useUploadImageToCloud = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addRoomImageToCloud,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["rooms"] });
    },
  });
};
