import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfiguration, tokenScopes } from "../config/authConfig";

export const msalPCA = new PublicClientApplication(msalConfiguration);

export const msalLogin = async () => {
  return await msalPCA.loginRedirect();
};

export const msalLogout = async () => {
  return await msalPCA.logoutRedirect({
    postLogoutRedirectUri: window.location.origin,
  });
};

export const getMsalToken = async () => {
  const accounts = msalPCA.getAllAccounts();
  if (!accounts[0]) {
    console.log("user is not signed in");
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }

  const response = await msalPCA.acquireTokenSilent({
    scopes: tokenScopes,
    account: accounts[0],
  });
  return response.accessToken;
};
