export enum StoreStatus {
  "ACTIVE" = "ACTIVE",
  "INACTIVE" = "INACTIVE",
}

export type Store = {
  storeId: string;
  name: string;
  status: StoreStatus;
  locales: string[];
  primaryLocale: string;
  countryId: string;
  timezone: string;
  isVirtual: boolean;
  createdAt: Date;
  updatedAt?: Date | undefined;
};

export type AddStoreData = {
  storeId: string;
  isVirtual: boolean;
  countryId: string;
  name: string;
  status: string;
  locales: string[];
  primaryLocale: string;
  timezone: string;
  createdAt: number;
};

export type Locale = {
  isoCode: string;
  country: string;
  isoCodeLang: string;
  language: string;
};

export interface StoreData {
  name: string;
  store_id: string;
}
