export type Region = "europe" | "na" | "asia";
const GERMANY_COUNTRY_ID = "DE";

export const REGIONS = (["europe", "na", "asia"] as Region[]).filter((region) => {
  const subdomain = window.location.hostname.split(".")[0];
  switch (subdomain) {
    case "fixa-admin-dev":
      return region !== "asia";
    case "fixa-admin-stage":
      return region === "europe";
    default:
      return true;
  }
});

export const getLabelForRegion = (region: Region) => {
  switch (region) {
    case "europe":
      return "Europe";
    case "na":
      return "North America";
    case "asia":
      return "Asia";
  }
};

export function stringIsRegion(region: string | undefined): region is Region {
  if (!region) return false;
  return REGIONS.includes(region as Region);
}

export function userIsFromGermany(user: { countryId: string }) {
  if (!user) {
    return false;
  }
  return user.countryId === GERMANY_COUNTRY_ID;
}
