import React, { ChangeEvent, useState } from "react";
import Button from "@ingka/button";
import Modal, { Sheets, ModalFooter, ModalHeader } from "@ingka/modal";
import TextArea from "@ingka/text-area";
import { ModalItemWrapper } from "../../../config/styles";
import useCreateArticlesBulk from "../hooks/articles/useCreateArticlesBulk";
import { PaddedHorizontalContainer } from "../../../styles/styles";
import { useArticlesInfo } from "../hooks/articles/useArticlesInfo";
import { extractProductArticleIdAndQuantity, isValidInput } from "./AddArticlesForm.helper";
import { ProductArticleIdAndQuantity } from "../types/Articles.types";
import { transformArticlesInfos } from "../helpers/transform.articles.helper";
import FormField from "@ingka/form-field";

interface AddArticlesFormProps {
  roomId: string;
  articlesAdded: (isSuccess: boolean) => Promise<void>;
}

export const AddArticlesForm = ({ roomId, articlesAdded }: AddArticlesFormProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [formattedInputValues, setFormattedInputValues] = useState<ProductArticleIdAndQuantity[]>([]);

  const productArticleIds = formattedInputValues.map((o) => o.productArticleId);
  const { data: articlesInfo, isLoading: isArticleInfosLoading } = useArticlesInfo(productArticleIds);
  const createArticlesMutation = useCreateArticlesBulk();

  React.useEffect(() => {
    const addArticles = async () => {
      if (!articlesInfo) return;
      const allArticles = transformArticlesInfos(roomId, formattedInputValues, articlesInfo);
      if (allArticles.length > 0) {
        await createArticlesMutation.mutateAsync(allArticles);
        await articlesAdded(true);
      }
      setModalVisibility(false);
      setInputValue("");
    };
    addArticles().catch(async () => {
      await articlesAdded(false);
      setModalVisibility(false);
    });
  }, [articlesInfo, formattedInputValues]);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    const idAndQuantity = extractProductArticleIdAndQuantity(inputValue);
    setFormattedInputValues(idAndQuantity);
  };

  const isLoading = createArticlesMutation.isPending || isArticleInfosLoading;

  return (
    <>
      <Button type="emphasised" onClick={() => setModalVisibility(!modalVisibility)}>
        Add articles
      </Button>
      <Modal visible={modalVisibility} handleCloseBtn={() => setModalVisibility(false)}>
        <Sheets
          labelledById="add-articles"
          header={<ModalHeader ariaCloseTxt="Close prompt." title="Add articles" />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                loading={isLoading}
                disabled={!inputValue || isValidInput(inputValue)}
              >
                Add articles
              </Button>
            </ModalFooter>
          }
        >
          <PaddedHorizontalContainer>
            <ModalItemWrapper>
              <FormField
                shouldValidate={isValidInput(inputValue)}
                validation={{
                  msg: "Please enter a valid article number.",
                  id: "article-number",
                }}
              >
                <TextArea
                  label={
                    "Enter, per line, an article number (allowed formats 'XXXXXXXX', 'XXX.XXX.XX' 'XXX-XXX-XX') and optionally the quantity separated with a single space. If quantity is omitted it will be defaulted to 1. Examples: '123.456.78' or '12345678 3'"
                  }
                  value={inputValue}
                  rows={25}
                  onChange={handleInputChange}
                />
              </FormField>
            </ModalItemWrapper>
          </PaddedHorizontalContainer>
        </Sheets>
      </Modal>
    </>
  );
};
