import React from "react";
import Switch from "@ingka/switch";
import { TableBody } from "@ingka/table";
import { Store, StoreStatus } from "../types/Stores.types";

export const SingleStoreData = ({ store }: { store: Store }) => {
  return (
    <TableBody style={{ backgroundColor: "white" }}>
      <tr>
        <td>{store.name}</td>
        <td>{store.storeId}</td>
        <td>
          <Switch
            id={store.storeId}
            value={store.status}
            checked={store.status === StoreStatus.ACTIVE}
            onChange={() => ""}
          />
        </td>
      </tr>
    </TableBody>
  );
};
