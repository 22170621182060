import styled from "styled-components";
import Image from "@ingka/image";
import Checkbox from "@ingka/checkbox";
import CommercialMessage from "@ingka/commercial-message";

import "@ingka/commercial-message/dist/style.css";

export const CarouselImageSize = styled(Image)<{ $rotation: number }>`
  width: 80px;
  height: 110px;
  object-fit: cover;
  transform: rotate(${({ $rotation }) => $rotation}deg);
`;

export const ItemTitle = styled.p`
  font-weight: bold;
  margin-right: 0.5em;
`;

export const PaddedHorizontalContainer = styled.div`
  padding: 0 1rem;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0px 1px 0px var(--grey200);
  &:last-child {
    box-shadow: 1px 0px 0px 1px var(--grey200);
  }
`;

export const LeftContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CenterContainer = styled.div<{ $centerText: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerText }) => ($centerText ? "center" : "left")};
  text-align: ${({ $centerText }) => ($centerText ? "center" : "left")};
`;

export const RightContainer = styled.div`
  flex-grow: 0;
`;

export const CheckBoxTopRight = styled(Checkbox)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FlexRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const SalesStopMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: #cb3553;
  background-color: #f7e7ed;
`;

export const MainProductMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  background-color: var(--blue-medium);
`;

export const EDSMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: var(--black);
  background-color: var(--grey100);
`;

export const VerticalAlignedTableCell = styled.td`
  vertical-align: middle;
`;

export const BackupProductMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: var(--blue-medium);
  background-color: var(--blue-lighter);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: end;
  padding-top: 15px;
  margin-left: 15px;
`;
