import { useQuery } from "@tanstack/react-query";
import { getEDSData } from "../../services/articles.service";

export const useEDSData = (storeId: string, articleNumbers: string) => {
  return useQuery({
    queryKey: ["EDS", storeId, articleNumbers],
    queryFn: () => getEDSData(storeId, articleNumbers),
    enabled: !!storeId && !!articleNumbers,
    gcTime: 1000 * 60 * 15, // 15 minutes cache time
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });
};
