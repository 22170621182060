import React, { useState } from "react";
import Modal, { Prompt, ModalHeader, Sheets, ModalBody } from "@ingka/modal";
import Button from "@ingka/button";
import { CreateUserType } from "../components/users/types/users.types";

export enum ModalOption {
  ADD = "ADD",
  EDIT = "EDIT",
  ACTIVE_CLICK = "ACTIVE_CLICK",
  REGION = "REGION",
}

interface PromptProps {
  buttonText: string;
  description: string;
  option: ModalOption;
  onClose: () => void;
  children?: React.ReactNode;
  user?: CreateUserType;
}

interface SheetsProps {
  open: boolean;
  onClose: () => void;
  headerTitle: string;
  children?: React.ReactNode;
}

export const SkapaPromptModal = ({ buttonText, description, option, onClose, children }: PromptProps) => {
  const [modalOpen, setModalOpen] = useState(option != ModalOption.ADD);
  const modalTitleId = buttonText;
  const handleClose = () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      {option === ModalOption.ADD && (
        <Button
          type="emphasised"
          text={buttonText}
          onClick={() => {
            setModalOpen(true);
          }}
        />
      )}
      <Modal visible={modalOpen} escapable={true} handleCloseBtn={() => handleClose()}>
        <Prompt
          title={buttonText}
          titleId={modalTitleId}
          header={<ModalHeader ariaCloseTxt="Close prompt" />}
          footer={null}
        >
          {description}
          {children}
        </Prompt>
      </Modal>
    </>
  );
};

export const SkapaSheetModal = ({ open, onClose, headerTitle, children }: SheetsProps) => {
  const [modalOpen, setModalOpen] = useState(open);
  const handleClose = () => {
    setModalOpen(false);
    onClose();
  };
  return (
    <>
      <Modal visible={modalOpen} escapable={true} handleCloseBtn={() => handleClose()}>
        <Sheets
          alignment={"right"}
          header={<ModalHeader title={headerTitle} ariaCloseTxt="Close prompt" />}
          footer={null}
          children={<ModalBody>{children}</ModalBody>}
        />
      </Modal>
    </>
  );
};
