import { useQuery } from "@tanstack/react-query";
import { fetchUsersByCountryOrStoreId } from "../services/users.service";

export const useFetchUsersByNameAndCountry = (fullName: string, country: string, store: string | undefined) => {
  return useQuery({
    queryKey: ["users", fullName, country, store],
    queryFn: () => fetchUsersByCountryOrStoreId(fullName, country, store),
    staleTime: 1000 * 60,
    enabled: !!fullName,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
