import { makeServiceHeaders, roomSettingsServiceBaseUrl } from "../../../services/shared.service";
import { Groups } from "../types/groups.types";

const BASE_URL = `${roomSettingsServiceBaseUrl()}/groups`;

export const getGroupsByRoom = async (roomId: string) => {
  const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
    method: "GET",
    headers: await makeServiceHeaders(),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const groups = (await response.json()) as Groups;
  return groups;
};
