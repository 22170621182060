import { Teams, AddTeamData, PartialTeam, Team, CreateNewTeam, TeamData } from "../types/Teams.types";
import { coreServiceBaseUrl, makeServiceHeaders } from "../../../services/shared.service";
import { getMsalToken } from "../../../services/auth.service";
import packageJson from "../../../../package.json";

const TEAMS_BASE_URL = `${coreServiceBaseUrl()}/teams`; //"http://localhost:8080/teams";

export const getAllTeams = async () => {
  try {
    const response = await fetch(TEAMS_BASE_URL, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: Teams = (await response.json()) as Teams;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const getTeamById = async (teamId: string) => {
  try {
    const response = await fetch(`${TEAMS_BASE_URL}/${teamId}`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: Team = (await response.json()) as Team;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
export const getTeamsByStoreId = async (storeId: string) => {
  const encodedStoreId = encodeURIComponent(storeId);
  try {
    const response = await fetch(`${TEAMS_BASE_URL}/store/${encodedStoreId}`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: TeamData[] = (await response.json()) as TeamData[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const addTeam = async (teamData: CreateNewTeam) => {
  if (!teamData.name || !teamData.store_id) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(TEAMS_BASE_URL + "/", {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(teamData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data: AddTeamData = (await response.json()) as AddTeamData;
  return data;
};

export const updateTeam = async (teamId: string, teamData: PartialTeam) => {
  if (!teamData.name || !teamData.store_id) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(`${TEAMS_BASE_URL}/${teamId}`, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(teamData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  let data: unknown;
  if (response.headers.get("content-type")?.includes("application/json")) {
    data = (await response.json()) as unknown;
  } else {
    data = await response.text();
  }
  return data;
};

export const deleteTeam = async (teamId: string) => {
  try {
    const response = await fetch(`${TEAMS_BASE_URL}/${teamId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await getMsalToken()}`,
        "X-Client-Version": packageJson.version,
      },
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return;
  } catch (error) {
    console.error(error);
  }
};
