import React from "react";
import arrowClockwise from "@ingka/ssr-icon/paths/arrow-clockwise";
import Button from "@ingka/button";

interface UpdateArticlesButtonProps {
  updateArticlesButtonPressed: () => Promise<void>;
}

export const UpdateArticlesButton = ({ updateArticlesButtonPressed }: UpdateArticlesButtonProps) => {
  const handleUpdate = async () => {
    await updateArticlesButtonPressed();
  };

  return <Button ssrIcon={arrowClockwise} onClick={handleUpdate} text={"Update Unknown articles"} type={"tertiary"} />;
};
