import React from "react";
import Select, { Option } from "@ingka/select";
import useCountries from "../hooks/useCountries";

export interface CountryState {
  selectedCountry: string;
  setCountry: (country: string) => void;
  showCountry?: boolean;
}

export const CountrySelector = ({ selectedCountry, setCountry, showCountry }: CountryState) => {
  const countries = useCountries();
  return (
    <Select
      value={selectedCountry}
      id="countries"
      label={"Country"}
      hintText={"Country selector"}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setCountry(e.target.value)}
      disabled={!showCountry}
    >
      <Option value="" label="All countries" />
      {countries.data?.map(({ isoCode, name }) => <Option key={isoCode} value={isoCode} label={name} />)}
    </Select>
  );
};
