import { useQuery } from "@tanstack/react-query";
import { getAllCountries } from "../services/countries.service";

const useCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: getAllCountries,
    staleTime: 1000 * 60 * 60, // 1hr since this data changes almost never
  });
};

export default useCountries;
