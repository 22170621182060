import React from "react";
import Button from "@ingka/button";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";

interface PromptProps {
  message: string;
  closeModal: () => void;
}

export const MessageModal = ({ message, closeModal }: PromptProps) => {
  return (
    <Modal visible={!!message} handleCloseBtn={() => closeModal()}>
      <Prompt
        title={"Message"}
        titleId={"message-modal"}
        header={<ModalHeader ariaCloseTxt={"Close modal"} />}
        footer={
          <ModalFooter>
            <Button
              text={"Close"}
              onClick={() => {
                closeModal();
              }}
            />
          </ModalFooter>
        }
      >
        <p>{message}</p>
      </Prompt>
    </Modal>
  );
};
