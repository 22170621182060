import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createStoreContact } from "../services/storeContacts.service";

export const useCreateStoreContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStoreContact,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["store-contacts"] });
    },
  });
};
