import { useQuery } from "@tanstack/react-query";
import { getStoresByCountryId } from "../services/stores.service";

const useStoresByCountryId = (countryId: string | undefined) => {
  return useQuery({
    queryKey: ["stores", countryId],
    queryFn: () => getStoresByCountryId(countryId),
    enabled: !!countryId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 7 * 24 * 60 * 60 * 1000,
  });
};

export default useStoresByCountryId;
