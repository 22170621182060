import { PartialRoom, RoomId, RoomSetting, RoomSettingObj, RoomSettings } from "../types/rooms.types";
import { roomSettingsServiceBaseUrl } from "../../../services/shared.service";
import { makeServiceHeaders } from "../../../services/shared.service";
import { Articles } from "../types/Articles.types";

const BASE_URL = `${roomSettingsServiceBaseUrl()}/rooms`;

export const getAllRooms = async () => {
  try {
    const response = await fetch(BASE_URL, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: RoomSettings = (await response.json()) as RoomSettings;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const getRoomsByStoreId = async (storeId: string): Promise<RoomSettings> => {
  const emptyRooms = { rooms: [] } as RoomSettings;
  try {
    const response = await fetch(`${BASE_URL}/stores/${encodeURIComponent(storeId)}`, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (response.status === 404) return emptyRooms;

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: RoomSettings = (await response.json()) as RoomSettings;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return emptyRooms;
};

export const getRoomById = async (roomId: string | undefined) => {
  if (!roomId) return Promise.resolve(undefined);

  try {
    const response = await fetch(`${BASE_URL}/${roomId}`, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: RoomSettingObj = (await response.json()) as RoomSettingObj;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const getArticlesByRoomId = async (roomId: string | undefined) => {
  if (!roomId) return Promise.resolve(undefined);

  try {
    const response = await fetch(`${BASE_URL}/${roomId}/articles`, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data: Articles = (await response.json()) as Articles;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const addRoom = async (roomData: RoomSetting) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(roomData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as RoomId;
  return data;
};

export const updateRoom = async (roomId: string, roomData: PartialRoom) => {
  const response = await fetch(`${BASE_URL}/${roomId}`, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(roomData),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data: RoomSetting = (await response.json()) as RoomSetting;
  return data;
};
