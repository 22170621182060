import React from "react";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import Button from "@ingka/button";

interface DeleteImageButtonProps {
  deleteImage: (images: string[]) => void;
  selectedImages: string[];
  disabled: boolean;
}

export const DeleteImageButton = ({ deleteImage, selectedImages, disabled }: DeleteImageButtonProps) => {
  const handleDelete = () => {
    deleteImage(selectedImages);
  };

  return (
    <Button
      style={{ backgroundColor: "var(--pink)", color: "var(--white)" }}
      disabled={disabled}
      ssrIcon={trashCan}
      onClick={handleDelete}
      text={"Delete selected"}
    />
  );
};
