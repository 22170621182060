import { useQuery } from "@tanstack/react-query";
import { getTeamById } from "../services/teams.service";

const useTeam = (teamId: string) => {
  return useQuery({
    queryKey: ["team", teamId],
    queryFn: () => getTeamById(teamId),
    staleTime: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useTeam;
