import React, { useEffect, useState } from "react";
import Button from "@ingka/button";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import { Team } from "../types/Teams.types";
import useUpdateTeam from "../hooks/useUpdateTeam";

interface UpdateTeamStatusModalProps {
  team: Team;
  activeClicked: boolean;
  onClose: () => void;
}

export const UpdateTeamStatusModal = ({ team, activeClicked, onClose }: UpdateTeamStatusModalProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const updatedStatus = team.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
  const updateTeamStatusMutation = useUpdateTeam();

  const handleTeamStatusChange = async () => {
    await updateTeamStatusMutation.mutateAsync({
      team_id: team.id,
      store_id: team.storeId,
      name: team.name,
      is_product_quality: team.is_product_quality,
      updated_at: Math.floor(new Date(team.createdAt).getTime() / 1000),
      created_at: Math.floor(new Date(team.createdAt).getTime() / 1000),
      status: updatedStatus,
    });
    setModalVisibility(false);
    onClose();
  };

  useEffect(() => {
    setModalVisibility(activeClicked);
  }, []);

  return (
    <>
      <Modal
        visible={modalVisibility}
        handleCloseBtn={() => {
          onClose();
          setModalVisibility(false);
        }}
      >
        <Prompt
          titleId="change-team-active-status"
          title="Change active status"
          header={<ModalHeader ariaCloseTxt="Close prompt." />}
          footer={
            <ModalFooter>
              <Button onClick={handleTeamStatusChange}>Change active status</Button>
            </ModalFooter>
          }
        >
          <p>
            Are you sure you want to {team.status === "ACTIVE" ? "deactivate" : "activate"} {team.name}?
          </p>
        </Prompt>
      </Modal>
    </>
  );
};
