import React from "react";
import useGetTeamsByStoreId from "../../teams/hooks/useGetTeamsByStoreId";
import { Option } from "@ingka/select";

interface TeamsByStoreIdProps {
  storeId: string;
}

export const TeamsByStoreId = ({ storeId }: TeamsByStoreIdProps) => {
  const { data: teams } = useGetTeamsByStoreId(storeId);
  return <>{teams?.map((team) => <Option key={team.id} value={team.id} name={team.name} />)}</>;
};
