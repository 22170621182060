import { AddStoreData, Store } from "../types/Stores.types";
import { coreServiceBaseUrl, makeServiceHeaders } from "../../../services/shared.service";

const STORES_BASE_URL = `${coreServiceBaseUrl()}/stores`; //"http://localhost:8080/stores";

export const getAllStores = async () => {
  try {
    const response = await fetch(STORES_BASE_URL, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return (await response.json()) as Store[];
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const getStoreById = async (storeId: string | undefined) => {
  if (!storeId) return Promise.resolve(undefined);

  try {
    const response = await fetch(`${STORES_BASE_URL}/${encodeURIComponent(storeId)}`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return (await response.json()) as Store;
  } catch (error) {
    console.error(error);
  }
};

export const getStoresByCountryId = async (countryId: string | undefined) => {
  try {
    const response = await fetch(`${STORES_BASE_URL}/country/${countryId}`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return (await response.json()) as AddStoreData[];
  } catch (error) {
    console.error(error);
  }
};

export const addStore = async (storeData: AddStoreData) => {
  try {
    const response = await fetch(STORES_BASE_URL, {
      method: "POST",
      headers: await makeServiceHeaders(),
      body: JSON.stringify(storeData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return (await response.json()) as AddStoreData;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

export const updateStoreStatus = async (storeId: string, status: string) => {
  const storeStatusData = {
    storeId: storeId,
    data: {
      status: status,
    },
  };

  try {
    const response = await fetch(`${STORES_BASE_URL}/update-status`, {
      method: "PUT",
      headers: await makeServiceHeaders(),
      body: JSON.stringify(storeStatusData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    return (await response.json()) as Store;
  } catch (error) {
    console.error(error);
  }
};
