import React from "react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const getGraphToken = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [accessToken, setAccessToken] = React.useState({});

  React.useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      console.log("Requesting token before", accounts);

      const { homeAccountId, localAccountId, tenantId: accountTenantId } = accounts[0];
      const [uid, tenantId] = homeAccountId.split(".");

      if (uid && tenantId && (uid !== localAccountId || tenantId !== accountTenantId)) {
        console.log("Setting homeAccountId");
        accounts[0].homeAccountId = `${localAccountId}.${accountTenantId}`;
      }
      console.log("Requesting token after", accounts);

      const accessTokenRequest = {
        scopes: ["user.read"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          const token = accessTokenResponse.accessToken;
          const expires_at = accessTokenResponse.expiresOn;
          setAccessToken({ token, expires_at });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                const token = accessTokenResponse.accessToken;
                const expires_at = accessTokenResponse.expiresOn;
                setAccessToken({ token, expires_at });
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log("Interative token error", error);
              });
          }
          console.log("Access Token error", error);
        });
    }
  }, [instance, accounts, inProgress]);

  return accessToken;
};
export default getGraphToken;
