import React, { useState } from "react";
import { create } from "zustand";
import { HeaderButton } from "@coworker-design/layout-react";
import { SkapaSheetModal } from "../modals/SkapaSheetModal";
import globe from "@ingka/ssr-icon/paths/globe";
import { Region, getLabelForRegion } from "../utils/region.helpers";
import Cookies from "universal-cookie";
import { RegionModalChildren } from "../components/RegionOptions";

type RegionsState = {
  region: Region;
  setRegion: (region: Region) => void;
};

type RegionSwitcherProps = RegionsState & {
  setRegionLoader: (loading: boolean) => void;
};

const cookies = new Cookies();
// TODO can just use a context here
export const useRegionsStore = create<RegionsState>()((set) => ({
  region: (cookies.get("RegionSwitcher") as Region) || (cookies.get("BackendOrigin") as Region),
  setRegion: (region) => set({ region }),
}));

export const RegionSwitcher = ({ region, setRegion, setRegionLoader }: RegionSwitcherProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  React.useEffect(() => {
    console.log(`Region after setting: ${region}`);
  }, [region]);
  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegionLoader(true);
    setModalOpen(false);
    const newRegion = event.target.value as Region;
    setRegion(newRegion);
    cookies.set("RegionSwitcher", newRegion, { path: "/" });

    setTimeout(() => {
      window.location.reload();
    }, 3000); // Delaying reload so that the cookie and region is set before the page reloads
  };

  return (
    <>
      <HeaderButton
        ssrIcon={globe}
        onClick={() => {
          setModalOpen(true);
          console.log(`current region in state: ${region}`);
        }}
      >
        {getLabelForRegion(region)}
      </HeaderButton>
      {modalOpen && (
        <>
          <SkapaSheetModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            headerTitle={"Choose a region"}
            children={<RegionModalChildren region={region} handleRegionChange={handleRegionChange} />}
          />
        </>
      )}
    </>
  );
};
