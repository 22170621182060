import React from "react";
import { TableHeader } from "@ingka/table";

export const RoomSettingsTableHeader = () => {
  return (
    <TableHeader sticky={true}>
      <tr>
        <th>DISPLAY NAME</th>
        <th>NUMBER OF ARTICLES</th>
        <th>STYLE GROUP </th>
        <th>HF LTP </th>
        <th>PRICING </th>
        <th style={{ paddingRight: "220px" }}>ACTIVE </th>
      </tr>
    </TableHeader>
  );
};
