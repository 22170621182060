import React, { ReactNode } from "react";
import styled from "styled-components";

interface WarningTextWithIconProps {
  text: string;
  icon?: ReactNode;
}

const WarningTextWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #e00751;
  gap: 5px;
  font-size: 14px;
`;

export const WarningTextWithIcon = ({ text, icon }: WarningTextWithIconProps) => {
  return (
    <WarningTextWithIconContainer>
      {icon}
      {text}
    </WarningTextWithIconContainer>
  );
};
