import { buildQueryParams, getRoleName } from "../../../helpers/helpers";
import { UserRole } from "../../../types/roles.types";
import { CreateUserType, CreateUserData, UpdateUserType, User, UserAPI } from "../types/users.types";
import { callFetch, makeServiceHeaders, coreServiceBaseUrl } from "../../../services/shared.service";
import { ActiveUser } from "@coworker-design/layout-models";

const USERS_BASE_URL = `${coreServiceBaseUrl()}/users`;

export const fetchUsers = async (countryId: string, storeId: string, roleId: string): Promise<User[]> => {
  try {
    const queryParams = buildQueryParams(countryId, storeId, roleId);
    const url = `${USERS_BASE_URL}/fetch_users?${queryParams.toString()}`;
    const response = await fetch(url, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data: User[] = (await response.json()) as User[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};

export type UserData = {
  given_name: string;
  family_name: string;
  email: string;
  jobTitle: string;
  roleId: string;
  status: string;
  storeId: string;
  teamId: string;
  countryId: string;
  name: string;
  createdAt: string;
  fixaUid: string;
  hashedUid: string;
  lastActiveAt: string;
  lastLoginAt: string;
  uid: string;
  permissions: string[];
};

export const getUserByEmail = async (email: string): Promise<UserData> => {
  const user = await callFetch<UserAPI>("GET", `${USERS_BASE_URL}/email/${email}`);
  const userData = user?.data?.data;

  return {
    given_name: userData.given_name ?? userData.name.split(" ")[0],
    family_name: userData.surname ?? userData.name.split(" ")[1],
    email: userData.email,
    jobTitle: getRoleName(userData.role_id as UserRole),
    roleId: userData.role_id,
    status: userData.status,
    storeId: userData.store_id,
    teamId: userData.team_id,
    countryId: userData.country_id,
    name: userData.name,
    createdAt: userData.created_at,
    fixaUid: userData.fixa_uid,
    hashedUid: userData.hashed_uid,
    lastActiveAt: userData.last_activity,
    lastLoginAt: userData.last_login_at,
    uid: userData.uid,
    permissions: userData.permissions,
  };
};

export const updateUser = async (user: UpdateUserType | undefined) => {
  if (!user) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(`${USERS_BASE_URL}/${user?.fixaUid}`, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data: UpdateUserType = (await response.json()) as UpdateUserType;
  return data;
};
export const createUser = async (userData: CreateUserType) => {
  if (!userData.email || !userData.storeId || !userData.roleId || !userData.status || !userData.teamId) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(USERS_BASE_URL, {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const responseBody = (await response.json()) as { message: string };
    if (responseBody.message === "User already exists") {
      throw new Error("User already exists");
    }
    throw new Error("Network response problem");
  }

  const data: CreateUserData = (await response.json()) as CreateUserData;
  return data;
};

export const deleteUser = async (fixaUid: string) => {
  try {
    const response = await fetch(`${USERS_BASE_URL}/delete`, {
      method: "PUT",
      headers: await makeServiceHeaders(),
      body: JSON.stringify({ fixaUid }),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return;
  } catch (error) {
    console.error("Delete error:", error);
  }
};

// A mapping function from the default User type and the ActiveUser which is
// expected by the cwds configuration (ConfigProvider component)
export const convertUserToActiveUser = (user: User | undefined): ActiveUser => {
  if (!user) {
    return {
      given_name: "",
      family_name: "",
    };
  }

  const getPictureUrl = (givenName: string, familyName: string) => {
    return `https://i1.wp.com/cdn.auth0.com/avatars/${givenName[0].toLowerCase()}${familyName[0].toLowerCase()}.png`;
  };

  return {
    userId: user.uid,
    country: user.countryId,
    name: undefined, // user.fullName, // we don't actually add it because it breaks the header layout
    given_name: user.givenName,
    family_name: user.familyName,
    email: user.email,
    picture: getPictureUrl(user.givenName, user.familyName),
  };
};
