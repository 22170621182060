import React, { useState } from "react";
import InputField from "@ingka/input-field";
import Select, { Option } from "@ingka/select";
import { ModalItemWrapper } from "../../../config/styles";
import { CreateStoreContactData, StoreContact } from "../types/StoreContacts.types";
import Button from "@ingka/button";
import { useUpdateStoreContact } from "../hooks/useUpdateStoreContact";
import { Message } from "../../teams/organisms/UpdateTeamForm";
import { useDeleteStoreContact } from "../hooks/useDeleteStoreContact";
import useGetTeamsByStoreId from "../../teams/hooks/useGetTeamsByStoreId";
import { ConfirmCancelModal } from "../../../modals/ConfirmCancelModal";

interface StoreContactProps {
  storeContact: StoreContact;
  setUpdatedStoreContact: React.Dispatch<React.SetStateAction<CreateStoreContactData>>;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const EditStoreContactsForm = ({ storeContact, setEditModal, setSuccessMessage }: StoreContactProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: teams } = useGetTeamsByStoreId(storeContact.storeId);
  const { mutateAsync: updatedStoreContactMutation, isPending, isError, error } = useUpdateStoreContact();
  const deleteContactMutation = useDeleteStoreContact(storeContact.id);
  const [storeContactState, setStoreContactState] = useState<CreateStoreContactData>({
    storeId: storeContact.storeId || "",
    teamId: storeContact.teamId || "",
    countryId: storeContact.countryId || "",
    phoneName: storeContact.name || "",
    phoneNumber: storeContact.phoneNumber || "",
  });

  const handleDelete = async () => {
    await deleteContactMutation.mutateAsync();
    setIsModalVisible(false);
    setEditModal(false);
    setSuccessMessage(`Store contact: ${storeContact.name}, deleted successfully`);
    setEditModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      await updatedStoreContactMutation({ ...storeContactState, storeContactId: storeContact.id });
      setSuccessMessage(`Team: ${storeContact.name}, edited successfully`);
      setEditModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ModalItemWrapper>
      <Select
        id={"selectteam"}
        hintText={"Select a team/department"}
        label={"Team"}
        children={teams?.map((team) => <Option key={team.id} id={team.id} name={team.name} value={team.id} />)}
        value={storeContactState.teamId}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setStoreContactState((storeContactState) => ({
            ...storeContactState,
            teamId: event.target.value,
          }));
        }}
      />
      <InputField
        id={"contactName"}
        type={"text"}
        label={"Contact name"}
        value={storeContactState.phoneName}
        onChange={(event) => {
          setStoreContactState((storeContactState) => ({
            ...storeContactState,
            phoneName: event.target.value,
          }));
        }}
      />
      <InputField
        id={"phonenumber"}
        type={"text"}
        label={"Phone number"}
        value={storeContactState.phoneNumber}
        onChange={(event) => {
          setStoreContactState((storeContactState) => ({
            ...storeContactState,
            phoneNumber: event.target.value,
          }));
        }}
      />
      {isError && <Message color="red">{error.message}</Message>}
      <Button
        type="danger"
        fluid={false}
        style={{ backgroundColor: "#e00751", color: "#fff", float: "right" }}
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        Delete Store Contact
      </Button>
      <ConfirmCancelModal
        header="Confirm Delete"
        title={`Are you sure you want to delete store contact: ${storeContact.name}?`}
        message="This action cannot be undone."
        isVisible={isModalVisible}
        onOK={handleDelete}
        onCancel={handleCancel}
      />
      <Button type="primary" fluid onClick={handleSubmit} loading={isPending}>
        Update Store Contact
      </Button>
    </ModalItemWrapper>
  );
};
