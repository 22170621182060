import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addArticlesBulk } from "../../services/articles.service";

const useCreateArticlesBulk = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addArticlesBulk,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["articles"] });
    },
  });
};

export default useCreateArticlesBulk;
