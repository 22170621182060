import { useQuery } from "@tanstack/react-query";
import { getStoreById } from "../services/stores.service";

const useStore = (storeId: string | undefined) => {
  return useQuery({
    queryKey: ["store", storeId],
    queryFn: () => getStoreById(storeId),
  });
};

export default useStore;
