import { CreateStoreContactData, StoreContact, StoreContacts } from "../types/StoreContacts.types";
import { coreServiceBaseUrl, makeServiceHeaders } from "../../../services/shared.service";
import { getMsalToken } from "../../../services/auth.service";
import packageJson from "../../../../package.json";

const BASE_URL = `${coreServiceBaseUrl()}/stores/store-contacts`;

export const getStoreContactsByStoreId = async (storeId: string) => {
  const encodedStoreId = encodeURIComponent(storeId);
  try {
    const response = await fetch(`${BASE_URL}/store/${encodedStoreId}`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data: StoreContacts = (await response.json()) as StoreContacts;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllStoreContacts = async () => {
  try {
    const response = await fetch(`${BASE_URL}/all_store_contacts`, {
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data: StoreContacts = (await response.json()) as StoreContacts;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const createStoreContact = async (storeContactData: CreateStoreContactData) => {
  if (
    !storeContactData.phoneName ||
    !storeContactData.phoneNumber ||
    !storeContactData.teamId ||
    !storeContactData.storeId
  ) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(storeContactData),
  });

  if (!response.ok) {
    throw new Error("Response was not ok.");
  }
  const data: StoreContact = (await response.json()) as StoreContact;
  return data;
};

export const deleteStoreContact = async (storeContactId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/${storeContactId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${await getMsalToken()}`,
        "X-Client-Version": packageJson.version,
      },
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const updateStoreContact = async (
  storeContact: CreateStoreContactData | undefined,
  StoreContactId: string | undefined
) => {
  if (!storeContact?.phoneName || !storeContact?.phoneNumber || !storeContact?.teamId || !StoreContactId) {
    throw new Error("Please fill in all details");
  }
  const response = await fetch(`${BASE_URL}/${StoreContactId}`, {
    method: "PUT",
    headers: await makeServiceHeaders(),
    body: JSON.stringify(storeContact),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }
};
