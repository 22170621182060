import React, { PropsWithChildren } from "react";
import { User } from "../../users/types/users.types";
import Button from "@ingka/button";
import styled from "styled-components";
import { isChineseEnvironment } from "../../../services/shared.service";

export const CenterContainer = styled.div`
  justify-content: center;
  text-align: center;
  gap: 24px;
  margin: 48px;
`;

interface BlockRoomSettingsProps {
  user: User | undefined;
}

export const BlockRoomSettings = ({ user, children }: PropsWithChildren<BlockRoomSettingsProps>) => {
  if (!user) return <></>;
  if (user.roleId !== "SUPERUSER") {
    return (
      <CenterContainer>
        <h2>
          You can no longer create or edit rooms in Fixa Admin. <br />
          Please use the Fixa app instead. <br />
          All functionality is now conveniently available in one place.
          <br />-<br />
          Directly in the Fixa app.
        </h2>
        <br />
        <br />
        <Button
          type="primary"
          onClick={() => {
            if (isChineseEnvironment()) window.open("https://fixa.ingka-internal.cn");
            else window.open("https://fixa.ingka.com/roomsettings");
          }}
        >
          Goto the Fixa App
        </Button>
      </CenterContainer>
    );
  }

  return <>{children}</>;
};
