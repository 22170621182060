import React from "react";
import Switch from "@ingka/switch";
import { TableBody } from "@ingka/table";
import { StoreHideKeyData } from "../types/Stores.types";

export const SingleStoreData = ({ store }: { store: StoreHideKeyData }) => {
  return (
    <TableBody style={{ backgroundColor: "white" }}>
      <tr>
        <td>{store.name}</td>
        <td>{store.id}</td>
        <td>
          <Switch id={store.id} value={store.status} checked={store.status === "ACTIVE"} onChange={() => ""} />
        </td>
      </tr>
    </TableBody>
  );
};
