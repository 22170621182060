import styled, { createGlobalStyle } from "styled-components";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`;

export const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

export const GappedRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  gap: 10px;
`;

export const CenteredFlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const fixaColorScheme = `:root {
    --yellow: #FFCD3F;
    --blue: #151E44;
    --darkblue: #111836;
    --blue-medium: #0058A3;
    --related-blue: #007aff;
    --blue-family: #0077c1;
    --light-blue: #4595ff;
    --blue-lighter: #0058A31A;
    --green: #0A8A00;
    --green-darker: #087500;
    --pink: #E00751;
    --orange: #FFA524;
  
    --white: #ffffff;
    --black: #000000;
    --grey900: #111111;
    --grey800: #333333;
    --grey718: #484848;
    --grey700: #666666;
    --grey600: #767676;
    --grey500: #959595;
    --grey300: #c4c4c4;
    --grey200: #DFDFDF;
    --grey150: #F2F2F2;
    --grey100: #F5F5F5;
    --grey50: #FAFAFA;
  
    --success: #0A8A00;
    --warning: #FFA524;
    --error: #E00751;
    --new: #0058A3;
    --disabled: #929292;
  
    --in-stock: #009669;
    --bti-yellow: #ffd800;
    --bti-red: #ec012a;
    --white-ice: #cfdcef;
  
    --z-addonlocation: 1;
    --z-withinput: 0;
  
    --z-heading-menu: 1;
  
    --z-insights-1: 1;
    --z-insights-2: 2;
  
    --z-heading-follower: 2;
    --z-imageslider: 2;
    --z-popupmenu: 2;
    --z-previewimage: 2;
    --z-toast-2: 2;
  
    --z-dropdownmenu: 4;
    --z-multiselectcheckbox:4;
    --z-oneup: 100;
    --z-popup: 500;
    --z-popover: 600;
    --z-portal-container: 2;
    --z-toast: 700;
    --z-tooltip: 800;
  
    --height-header: 70px;
  }`;

export const Global = createGlobalStyle`
${() => fixaColorScheme}

@font-face {
  font-family: 'NotoSansIKEA';
  src: url(config/fonts/NotoSansIKEA-BoldItalic.woff2) format('woff2'), url(config/fonts/NotoSansIKEA-BoldItalic.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansIKEA';
  src: url(config/fonts/NotoSansIKEA-Italic.woff2) format('woff2'), url(config/fonts/NotoSansIKEA-Italic.woff) format('woff');
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansIKEA';
  src: url(config/fonts/NotoSansIKEA-Bold.woff2) format('woff2'), url(config/fonts/NotoSansIKEA-Bold.woff) format('woff');
  font-weight: bold;
}
@font-face {
  font-family: 'NotoSansIKEA';
  src: url(config/fonts/NotoSansIKEA-Regular.woff2) format('woff2'), url(config/fonts/NotoSansIKEA-Regular.woff) format('woff');
}
  
  body, html, #root {
    background: #ffffff;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: 0;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  a  {
  text-decoration: none;
    user-select: none;
    overscroll-behavior-y: contain;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-track {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
`;
