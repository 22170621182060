import React, { useState } from "react";
import Search from "@ingka/search";

interface SearchableItem {
  [key: string]: string | number | undefined;
}

interface SearchBarProps<T> {
  id: string;
  items: T[] | undefined;
  searchProps: string[];
  onSearchResults: (results: T[]) => void;
}

export const SearchBar = <T extends SearchableItem>({ id, items, searchProps, onSearchResults }: SearchBarProps<T>) => {
  const [searchInput, setSearchInput] = useState<string>("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!items) return;

    const searchInput = e.target.value;

    setSearchInput(searchInput);
    onSearchResults(
      items.filter((item) =>
        searchProps.some((prop) => String(item[prop]).toLowerCase().includes(searchInput.toLowerCase()))
      )
    );
  };

  const handleClear = () => {
    setSearchInput("");
    onSearchResults(items || []);
  };

  return <Search id={id} value={searchInput} onChange={handleSearch} onClear={handleClear} placeholder={"Search"} />;
};
