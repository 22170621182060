import { useQuery } from "@tanstack/react-query";
import { getRoomById } from "../../services/rooms.service";

const useRoom = (roomId: string | undefined) => {
  return useQuery({
    queryKey: ["room", { id: roomId }],
    queryFn: () => getRoomById(roomId),
  });
};

export default useRoom;
