import { useState, useEffect } from "react";
import { ImageFileType } from "../../types/rooms.types";
import { getSignedUrl } from "../../services/images.service";
import { useImagesByRoomId } from "./useImagesByRoomId";

export const useImagesBySignedUrl = (roomId: string) => {
  const [signedImageUrls, setSignedImageUrls] = useState<string[]>([]);
  const { data: images } = useImagesByRoomId(roomId);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      if (images) {
        const imageFileNames: ImageFileType[] = (images || []).map((image) => {
          return { file_name: image.fileName };
        });
        const signedUrlResponse = await getSignedUrl(imageFileNames, "large");
        if (signedUrlResponse && Array.isArray(signedUrlResponse.urls)) {
          setSignedImageUrls(signedUrlResponse.urls || []);
        }
      }
    };
    void fetchSignedUrls();
  }, [images]);

  return signedImageUrls;
};
