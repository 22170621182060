import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByRoom } from "../../services/roomTasks.service";

export const useTaskLinksByRoom = (roomId: string) => {
  return useQuery({
    queryKey: ["taskLinksByRoom", roomId],
    queryFn: () => getTaskLinksByRoom(roomId),
    enabled: !!roomId,
  });
};
