import React from "react";
import Table, { TableBody, TableHeader } from "@ingka/table";
import { useFetchUsers } from "../../users/hooks/useFetchUsers";
import { UserSettings } from "../../users/types/users.types";
import dayjs from "dayjs";
import { getRoleName } from "../../../helpers/helpers";

export const TeamUsersTable = ({
  country,
  store,
  teamId,
  role,
  teamName,
}: UserSettings & { teamId: string; teamName: string }) => {
  const allUsers = useFetchUsers(country, store, role).data;
  const users = allUsers?.filter((user) => user.teamId === teamId);

  return (
    <Table style={{ zIndex: 1 }} fullWidth inset>
      <TableHeader sticky={true}>
        <tr>
          <th>Name </th>
          <th>Team </th>
          <th>Store </th>
          <th>Role </th>
          <th>Last seen </th>
        </tr>
      </TableHeader>
      <TableBody style={{ backgroundColor: "white" }}>
        {users &&
          users?.map((user) => (
            <tr key={user.fixaUid}>
              <td>{user.fullName}</td>
              <td>{teamName}</td>
              <td>{user.storeId}</td>
              <td>{getRoleName(user.roleId)}</td>
              <td>{dayjs(user.lastActiveAt).format("YYYY-MM-DD")}</td>
            </tr>
          ))}
      </TableBody>
    </Table>
  );
};
