import { useQuery } from "@tanstack/react-query";
import { getRoomsByStoreId } from "../../services/rooms.service";

const useRoomsByStoreId = (storeId: string) => {
  return useQuery({
    queryKey: ["rooms", { id: storeId }],
    queryFn: () => getRoomsByStoreId(storeId),
  });
};

export default useRoomsByStoreId;
