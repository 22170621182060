import React from "react";
import { TableHeader } from "@ingka/table";

export const StoreContactsTableHeader = () => {
  return (
    <TableHeader sticky={true}>
      <tr>
        <th>Contact name</th>
        <th>Phone number</th>
        <th>Store</th>
        <th>Team</th>
      </tr>
    </TableHeader>
  );
};
