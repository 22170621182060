import React from "react";
import "./App.css";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalPCA } from "./services/auth.service";
import { Global } from "./config/styles";
import { Login } from "./components/Login";
import Home from "./components/Home";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundary";

function App() {
  return (
    <>
      <Global />
      <MsalProvider instance={msalPCA}>
        <UnauthenticatedTemplate>
          <Login hasAdminRights={true} />
        </UnauthenticatedTemplate>

        <AuthenticatedTemplate>
          <BrowserRouter>
            <ErrorBoundary fallback={<Login hasAdminRights={false} />}>
              <Home />
            </ErrorBoundary>
          </BrowserRouter>
        </AuthenticatedTemplate>
      </MsalProvider>
    </>
  );
}

export default App;
