import { TaskLinks } from "../types/taskLinks.types";
import { makeServiceHeaders, roomSettingsServiceBaseUrl } from "../../../services/shared.service";

const BASE_URL = `${roomSettingsServiceBaseUrl()}/tasks`;

export const getTaskLinksByRoom = async (roomId: string) => {
  try {
    const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
      method: "GET",
      headers: await makeServiceHeaders(),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    const data = (await response.json()) as TaskLinks;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
