import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTeam } from "../services/teams.service";

const useCreateTeam = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addTeam,
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
};

export default useCreateTeam;
