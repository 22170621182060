import { FileUploadButton } from "../molecules/FileUploadButton";
import { useUploadImageToCloud } from "../hooks/images/useUploadImageToCloud";
import { useUploadImage } from "../hooks/images/useUploadImage";
import {
  CarouselImageSize,
  CheckBoxTopRight,
  LeftContainer,
  ListItemContainer,
  PaddedHorizontalContainer,
  RightContainer,
  FlexSpaceBetween,
} from "../../../styles/styles";
import useUpdateRoom from "../hooks/rooms/useUpdateRoom";
import useRoom from "../hooks/rooms/useRoom";
import useArticlesByRoomId from "../hooks/articles/useArticlesByRoomId";
import React, { useState, useEffect } from "react";
import { PartialRoom, RoomSettingImage, RoomSettingImages } from "../types/rooms.types";
import Switch from "@ingka/switch";
import Modal, { Sheets, ModalFooter, ModalHeader } from "@ingka/modal";
import Button from "@ingka/button";
import { ModalItemWrapper } from "../../../config/styles";
import InputField from "@ingka/input-field";
import Select, { Option } from "@ingka/select";
import Carousel from "@ingka/carousel";
import { StyleGroupData, HFLTPData, PricingData, LivingSituationData } from "../../../data/constants";
import { useImagesByRoomId } from "../hooks/images/useImagesByRoomId";
import { DeleteImageButton } from "../molecules/DeleteImageButton";
import { useDeleteImages } from "../hooks/images/useDeleteImages";
import { Label } from "../atoms/Label";
import { useImagesBySignedUrl } from "../hooks/images/useImagesBySignedUrl";
import { WarningTextWithIcon } from "../molecules/WarningTextWithIcon";
import TextArea from "@ingka/text-area";
import useRoomsByStoreId from "../hooks/rooms/useRoomsById";
import FormField from "@ingka/form-field";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/focus/dist/style.css";

import tag from "@ingka/ssr-icon/paths/tag";
import filledStar from "@ingka/ssr-icon/paths/star-filled";
import star from "@ingka/ssr-icon/paths/star";
import paintbrush from "@ingka/ssr-icon/paths/paintbrush";
import bike from "@ingka/ssr-icon/paths/bike";
import home from "@ingka/ssr-icon/paths/home";
import flag from "@ingka/ssr-icon/paths/flag";
import stairs from "@ingka/ssr-icon/paths/stairs";
import family from "@ingka/ssr-icon/paths/family-gender-mixed";
import { NoticeRedSmall } from "../atoms/icons/NoticeRedSmall";
import { useParams } from "react-router-dom";
import { getArticleName, getFullArticleName } from "./UpdateRoomSettingForm.helper";
import { updateImage } from "../services/images.service";
import { useQueryClient } from "@tanstack/react-query";
import { Rotate } from "../atoms/icons/Rotate";
import { Departments } from "../../../data/departments";
import { MessageModal } from "../../../modals/MessageModal";

interface UpdateRoomSettingsFormProps {
  roomId: string;
}

export const UpdateRoomSettingForm = ({ roomId }: UpdateRoomSettingsFormProps) => {
  const queryClient = useQueryClient();
  const { storeNumber } = useParams();
  const { data } = useRoom(roomId);
  const { data: roomArticles } = useArticlesByRoomId(roomId);
  const uploadImageToCloud = useUploadImageToCloud();
  const [images, setImages] = useState<RoomSettingImages>([]);
  const uploadImage = useUploadImage();
  const { data: roomImages, refetch } = useImagesByRoomId(roomId);
  const { data: rooms } = useRoomsByStoreId(storeNumber || "");
  const updateRoomMutation = useUpdateRoom(roomId, storeNumber || "");
  const [modalVisibility, setModalVisibility] = useState(false);
  const [room, setRoom] = useState<PartialRoom>({
    name: "",
    style_group: "",
    hf_ltp: "",
    pricing: "",
    active: "",
    store_id: "",
  });
  const [showButton, setShowButton] = useState<"upload" | "delete">("upload");
  const [isCancel, setIsCancel] = useState(false);
  const deleteImageMutation = useDeleteImages();
  const [selectImage, setSelectImage] = useState<string[]>([]);
  const [selectButtonClick, setSelectButtonClick] = useState(false);
  const [status, setStatus] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState<string | undefined>();

  const signedUrls = useImagesBySignedUrl(roomId);

  const noRoomName = room.name?.length === 0;

  const checkRoomName = () => {
    const found = rooms?.rooms.find((roomData) => roomData.name === room.name && roomData.id !== roomId);

    if (found) return true;

    return false;
  };

  const checkEmptyRoomName = () => {
    const isRoomNameEmpty = !room.name;
    if (isRoomNameEmpty) return true;

    return false;
  };

  const validationMessage = () => {
    if (checkRoomName()) {
      return `Room with name ${room.name} already exists`;
    } else if (checkEmptyRoomName()) {
      return "Room name cannot be empty";
    }
  };

  const toggleStatus = () => {
    if (noRoomName) {
      if (status === true) {
        setStatus(false);
      }
      return true;
    }
    return false;
  };

  const handleDeleteImage = (imageIds: string[]) => {
    deleteImageMutation.mutate({ imageIds, roomId });
    setSelectImage([]);
    setShowButton("upload");
    setSelectButtonClick(false);
  };

  const handleSelectedImage = (imageId: string) => {
    if (selectImage.includes(imageId)) {
      setSelectImage(selectImage.filter((id) => id !== imageId));
    } else {
      setSelectImage([...selectImage, imageId]);
    }
  };

  const handleSubmit = async () => {
    let isRoomUpdated = false;
    try {
      await updateRoomMutation.mutateAsync({
        ...room,
        active: status === true ? "ACTIVE" : "INACTIVE",
      });

      isRoomUpdated = true;
      if (images.length > 0) {
        await uploadImage.mutateAsync(images);
        setImages([]);
        refetch().catch((error) => {
          console.error("Error refetching room images:", error);
        });
      }
      setModalVisibility(false);
    } catch (error) {
      console.log(error);
      if (!isRoomUpdated) {
        setShowModalMessage("Could not update room. Please try again later.");
      } else {
        setShowModalMessage("Could not add room images. Please try again later.");
      }
    }
  };

  const handleAddImage = async (file: File) => {
    const uploadedImage = await uploadImageToCloud.mutateAsync(file);
    if (!uploadedImage) return;
    setImages((images) => [
      ...images,
      {
        url: uploadedImage.signed_url,
        roomId: roomId,
        folder: uploadedImage.folder,
        fileName: uploadedImage.file_name,
        rotation: 0,
      },
    ]);
  };

  const handleRotateImage = async (image: RoomSettingImage, index: number): Promise<void> => {
    try {
      image.rotation = image.rotation ? (image.rotation + 90) % 360 : 90;
      if (image.id) {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({ queryKey: ["roomImages"] });
      }

      const carouselImage = document.getElementById(`${image.id}-${index}`);
      if (carouselImage) {
        carouselImage.style.transform = "rotate(" + image.rotation + "deg)";
      }
    } catch (error) {
      console.log(error);
      setShowModalMessage("Could not rotate image. Please try again later.");
    }
  };

  useEffect(() => {
    if (data?.room) {
      const room = data.room;
      setRoom({
        id: room.id,
        name: room.name,
        style_group: room.style_group,
        hf_ltp: room.hf_ltp,
        pricing: room.pricing,
        active: room.active,
        store_id: room.store_id,
        main_article_id_1: room.main_article_id_1,
        main_article_id_2: room.main_article_id_2,
        main_article_name_1: room.main_article_name_1,
        main_article_name_2: room.main_article_name_2,
        backup_article_id_1: room.backup_article_id_1,
        backup_article_id_2: room.backup_article_id_2,
        backup_article_name_1: room.backup_article_name_1,
        backup_article_name_2: room.backup_article_name_2,
        media_story: room.media_story,
        living_situation: room.living_situation,
        living_conditions: room.living_conditions,
        laha: room.laha,
        hfb: room.hfb,
      });
    }
  }, [data]);

  useEffect(() => {
    if (room.active === "ACTIVE") {
      setStatus(true);
    } else if (room.active === "INACTIVE") {
      setStatus(false);
    }
  }, [room.active]);

  const allImages = [
    ...(roomImages?.map((roomImage, index) => ({
      ...roomImage,
      url: signedUrls[index],
    })) || []),
    ...(images || []),
  ];

  return (
    <>
      <Button type="secondary" onClick={() => setModalVisibility(!modalVisibility)}>
        Edit
      </Button>

      <Modal visible={modalVisibility} handleCloseBtn={() => setModalVisibility(false)}>
        <Sheets
          labelledById="edit-room-setting"
          header={<ModalHeader ariaCloseTxt="Close prompt." title="Edit room settings" />}
          footer={
            <ModalFooter>
              <Button type="primary" onClick={() => handleSubmit()} disabled={checkEmptyRoomName() || checkRoomName()}>
                Save details
              </Button>
            </ModalFooter>
          }
        >
          <PaddedHorizontalContainer>
            <ModalItemWrapper>
              <FormField
                shouldValidate={checkRoomName() || checkEmptyRoomName()}
                validation={{ msg: validationMessage() }}
              >
                <InputField
                  id={"name"}
                  type={"text"}
                  label={<Label text={"Media name"} withAsterisk />}
                  value={room.name}
                  ssrIcon={tag}
                  iconPosition={"leading"}
                  onChange={(event) => {
                    setRoom((room) => ({
                      ...room,
                      name: event.target.value,
                    }));
                  }}
                />
              </FormField>
              <div>
                <Label text={"Photos"} />
                {!!showModalMessage && (
                  <MessageModal
                    message={showModalMessage}
                    closeModal={() => {
                      setShowModalMessage(undefined);
                      setModalVisibility(false);
                    }}
                  />
                )}
                <Carousel id={"room-images"}>
                  {allImages?.map((image, index) => (
                    <div key={`${image.id}-${index}`} style={{ position: "relative", display: "inline-block" }}>
                      <CarouselImageSize
                        id={`${image.id}-${index}`}
                        key={`${image.id}-${index}`}
                        src={image.url}
                        alt={"Room image"}
                        $rotation={image.rotation || 0}
                      />
                      {selectButtonClick && image.id && (
                        <CheckBoxTopRight
                          id={image.id || ""}
                          value={image.id || ""}
                          onChange={() => {
                            if (image.id) {
                              handleSelectedImage(image.id);
                            }
                          }}
                        />
                      )}
                      <div
                        onClick={() => {
                          void handleRotateImage(image, index);
                        }}
                      >
                        <Rotate />
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              <ListItemContainer>
                <LeftContainer>
                  {showButton === "upload" && <FileUploadButton handleFile={handleAddImage} />}
                  {showButton === "delete" && (
                    <DeleteImageButton
                      deleteImage={handleDeleteImage}
                      selectedImages={selectImage}
                      disabled={selectImage.length === 0}
                    />
                  )}
                </LeftContainer>
                <RightContainer>
                  <Button
                    type={"secondary"}
                    onClick={() => {
                      setShowButton(showButton === "upload" ? "delete" : "upload");
                      setIsCancel(!isCancel);
                      setSelectButtonClick(!selectButtonClick === true ? true : false);
                      if (isCancel) {
                        setSelectImage([]);
                      }
                    }}
                    text={showButton === "upload" ? "Delete" : "Cancel"}
                  />
                </RightContainer>
              </ListItemContainer>
              <Select
                id={"main_article_id_1"}
                label={<Label text={"Main product #1"} />}
                value={room.main_article_id_1}
                ssrIcon={filledStar}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId = event.target.selectedIndex === 0 ? "" : event.target.value;
                  setRoom((room) => ({
                    ...room,
                    main_article_id_1: articleId,
                    main_article_name_1: getArticleName(roomArticles ?? [], articleId),
                  }));
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![room.main_article_id_2, room.backup_article_id_1, room.backup_article_id_2].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <FlexSpaceBetween>
                <Label text={"Active"} />
                <Switch
                  id="status"
                  checked={status}
                  value={room.active || ""}
                  onChange={() => setStatus(!status)}
                  disabled={toggleStatus()}
                />
              </FlexSpaceBetween>
              {toggleStatus() && (
                <WarningTextWithIcon
                  icon={<NoticeRedSmall />}
                  text="To activate a room, it is required to provide a name"
                />
              )}
              <Select
                id={"backup_article_id_1"}
                label={"Backup for product #1"}
                value={room.backup_article_id_1}
                ssrIcon={star}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId = event.target.selectedIndex === 0 ? "" : event.target.value;
                  setRoom((room) => ({
                    ...room,
                    backup_article_id_1: articleId,
                    backup_article_name_1: getArticleName(roomArticles ?? [], articleId),
                  }));
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![room.main_article_id_1, room.main_article_id_2, room.backup_article_id_2].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <Select
                id={"main_article_id_2"}
                label={"Main Product #2"}
                ssrIcon={filledStar}
                value={room.main_article_id_2}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId = event.target.selectedIndex === 0 ? "" : event.target.value;
                  setRoom((room) => ({
                    ...room,
                    main_article_id_2: articleId,
                    main_article_name_2: getArticleName(roomArticles ?? [], articleId),
                  }));
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![room.main_article_id_1, room.backup_article_id_1, room.backup_article_id_2].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <Select
                id={"backup_article_id_2"}
                label={"Backup for product #2"}
                value={room.backup_article_id_2}
                ssrIcon={star}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId = event.target.selectedIndex === 0 ? "" : event.target.value;
                  setRoom((room) => ({
                    ...room,
                    backup_article_id_2: articleId,
                    backup_article_name_2: getArticleName(roomArticles ?? [], articleId),
                  }));
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![room.main_article_id_1, room.backup_article_id_1, room.main_article_id_2].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <TextArea
                id={"media_story"}
                label={"Main story for the media"}
                style={{ height: "100px" }}
                value={room.media_story}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setRoom((room) => ({
                    ...room,
                    media_story: event.target.value,
                  }));
                }}
              />
              <Select
                id={"style_group"}
                label={"Style group"}
                hintText={"Select style group"}
                value={room.style_group}
                ssrIcon={paintbrush}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    style_group: event.target.selectedIndex === 0 ? "" : event.target.value,
                  }));
                }}
              >
                {StyleGroupData.map((styleGroup) => (
                  <Option key={styleGroup} value={styleGroup} name={styleGroup} />
                ))}
              </Select>
              <Select
                id={"living_situation"}
                label={"Living situation"}
                hintText={"Living situation"}
                ssrIcon={family}
                value={room.living_situation}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    living_situation: event.target.selectedIndex === 0 ? "" : event.target.value,
                  }));
                }}
              >
                {LivingSituationData.map((livingSituation, index) => (
                  <Option key={index} name={livingSituation} />
                ))}
              </Select>
              <Select
                id={"hf_ltp"}
                label={"Long-term priority"}
                hintText={"Long-term priority"}
                value={room.hf_ltp}
                ssrIcon={flag}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    hf_ltp: event.target.selectedIndex === 0 ? "" : event.target.value,
                  }));
                }}
              >
                {HFLTPData.map((hfltp) => (
                  <Option key={hfltp} name={hfltp} />
                ))}
              </Select>
              <Select
                id={"pricing"}
                label={"Price level"}
                hintText={"Set pricing"}
                value={room.pricing}
                ssrIcon={stairs}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    pricing: event.target.selectedIndex === 0 ? "" : event.target.value,
                  }));
                }}
              >
                {PricingData.map((pricing) => (
                  <Option key={pricing} name={pricing} />
                ))}
              </Select>
              <InputField
                id={"life_at_home_activity"}
                type={"text"}
                label={"Life at home activity"}
                value={room.laha}
                ssrIcon={bike}
                iconPosition={"leading"}
                onChange={(event) => {
                  setRoom((room) => ({
                    ...room,
                    laha: event.target.value,
                  }));
                }}
              />
              <InputField
                id={"living_conditions"}
                type={"text"}
                label={"Living conditions"}
                value={room.living_conditions}
                ssrIcon={home}
                iconPosition={"leading"}
                onChange={(event) => {
                  setRoom((room) => ({
                    ...room,
                    living_conditions: event.target.value,
                  }));
                }}
              />
              <Select
                id={"hfb"}
                label={"HFB location in store"}
                hintText={"Select HFB location"}
                value={room.hfb}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setRoom((room) => ({
                    ...room,
                    hfb: event.target.selectedIndex === 0 ? "" : event.target.value,
                  }));
                }}
              >
                {Departments.map((department, index) => (
                  <Option key={index} name={department.shortName} />
                ))}
              </Select>
            </ModalItemWrapper>
          </PaddedHorizontalContainer>
        </Sheets>
      </Modal>
    </>
  );
};
