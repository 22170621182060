import { useQuery } from "@tanstack/react-query";
import { getStoresByCountryId } from "../services/stores.service";

const useStoresByCountryId = (countryId: string | undefined) => {
  return useQuery({
    queryKey: ["stores", countryId],
    queryFn: () => getStoresByCountryId(countryId),
    enabled: !!countryId,
  });
};

export default useStoresByCountryId;
