import { useQuery } from "@tanstack/react-query";
import { getStoreById } from "../services/stores.service";

const useStore = (storeId: string | undefined) => {
  return useQuery({
    queryKey: ["store", "id", storeId],
    queryFn: () => getStoreById(storeId),
    staleTime: 1 * 24 * 60 * 60 * 1000, // 1 day
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!storeId,
  });
};

export default useStore;
