import React, { useState } from "react";
import styled from "styled-components";

import SSRIcon from "@ingka/ssr-icon";
import ChevronDownIcon from "@ingka/ssr-icon/paths/chevron-down";
import { Article } from "../types/Articles.types";
import { Group } from "../types/groups.types";
import { RoomSettingsArticlesTable } from "./RoomSettingsArticlesTable";
import { updateUnknownArticles } from "../helpers/updateArticles.helper";

const Container = styled.div`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

const Header = styled.div`
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  align-items: center;
`;

const Chevron = styled.div<{ opened: boolean }>`
  color: black;
  transform: rotate(${(props) => (props.opened ? "180deg" : "0deg")});
  transition: transform 0.3s;
  display: inline-block;
  align-self: center;
  margin-right: 16px;
  margin-left: 24px;
`;

const TitleSubtitleContainer = styled.div`
  flex-direction: column;
  display: flex;
`;

const Title = styled.div`
  font-weight: bold;
`;
const Subtitle = styled.div``;

const ArticlesListContainer = styled.div`
  background-color: white;
`;

export const GroupedArticlesList = ({ articleList, group }: { articleList: Article[]; group: Group }) => {
  const [isOpened, setIsOpened] = useState(false);

  const nbrArticles = articleList.length;
  const pieces = articleList.reduce((acc, cur) => acc + cur.nbrArticles, 0);
  const filteredArticles = articleList.filter((article) => article.groupId === group.id);
  const handleArticleChange: () => Promise<void> = async () => {};
  const handleArticlesAdded: () => Promise<void> = async () => {};

  const handleUpdateArticles = async () => {
    await updateUnknownArticles(filteredArticles);
    await handleArticlesAdded();
  };

  const handleChevronClick = () => {
    setIsOpened((prevState) => !prevState);
  };

  return (
    <Container className="grouped-article-list">
      <Header>
        <Chevron onClick={handleChevronClick} opened={isOpened}>
          <SSRIcon paths={ChevronDownIcon} />
        </Chevron>
        <TitleSubtitleContainer>
          <Title>{group.name}</Title>
          <Subtitle>
            {nbrArticles} articles, {pieces} pieces
          </Subtitle>
        </TitleSubtitleContainer>
      </Header>
      {isOpened && (
        <ArticlesListContainer>
          <RoomSettingsArticlesTable
            articles={filteredArticles}
            articleUpdated={handleArticleChange}
            handleUpdateArticles={handleUpdateArticles}
            groupId={group.id}
            showHeaders={false}
          />
        </ArticlesListContainer>
      )}
    </Container>
  );
};
