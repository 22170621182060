import React from "react";
import { TableHeader } from "@ingka/table";
import { UsersTable } from "../organisms/UsersTable";
import { User } from "../types/users.types";

export const UsersTableLabels = ({
  country,
  store,
  role,
  searchValue,
  onSort,
}: {
  country: string;
  store: string;
  role: string;
  searchValue: string;
  onSort: (field: string, direction: "asc" | "desc") => void;
}) => {
  const [sortField, setSortField] = React.useState<keyof User>("fullName");
  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">("asc");

  const handleSort = (field: string) => {
    const newDirection = sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field as keyof User);
    setSortDirection(newDirection);
    onSort(field, newDirection);
  };

  return (
    <>
      <TableHeader sticky={true}>
        <tr>
          <th onClick={() => handleSort("fullName")}>
            Name {sortField === "fullName" && (sortDirection === "asc" ? "↑" : "↓")}
          </th>
          <th onClick={() => handleSort("teamId")}>
            Team {sortField === "teamId" && (sortDirection === "asc" ? "↑" : "↓")}
          </th>
          <th onClick={() => handleSort("storeId")}>
            Store {sortField === "storeId" && (sortDirection === "asc" ? "↑" : "↓")}
          </th>
          <th onClick={() => handleSort("roleId")}>
            Role {sortField === "roleId" && (sortDirection === "asc" ? "↑" : "↓")}
          </th>
          <th onClick={() => handleSort("updatedAt")}>
            Last seen {sortField === "updatedAt" && (sortDirection === "asc" ? "↑" : "↓")}
          </th>
          <th>Active</th>
        </tr>
      </TableHeader>
      <UsersTable
        country={country}
        store={store}
        role={role}
        searchValue={searchValue}
        sortField={sortField}
        sortDirection={sortDirection}
      />
    </>
  );
};
