import React from "react";
import { TableBody } from "@ingka/table";
import { StoreContacts } from "../../storeContacts/types/StoreContacts.types";

export const StoreContactsData = ({ storeContacts }: { storeContacts: StoreContacts }) => {
  return (
    <>
      {storeContacts && (
        <TableBody style={{ backgroundColor: "white" }}>
          {storeContacts.map((storeContact) => (
            <tr key={storeContact.id}>
              <td>{storeContact.teamId}</td>
              <td>{storeContact.name}</td>
              <td>{storeContact.phoneNumber}</td>
            </tr>
          ))}
        </TableBody>
      )}
    </>
  );
};
