// Skapa
import Toast from "@ingka/toast";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/toast/dist/style.css";
import "@ingka/focus/dist/style.css";
import React, { useEffect } from "react";

interface ToastProps {
  text?: string;
  isOpen: boolean;
  onCloseRequest: () => void;
}

export const SkapaToast = ({ text, isOpen, onCloseRequest }: ToastProps) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onCloseRequest();
      }, 5000);
    }
  }, [isOpen, onCloseRequest]);

  return (
    <Toast
      style={{
        position: "fixed",
        top: "100px",
        left: "50%",
        transform: "translateX(-50%)",
        height: "70px",
        zIndex: 1000,
      }}
      text={text ?? ""}
      isOpen={isOpen}
      onCloseRequest={() => {
        onCloseRequest();
      }}
    />
  );
};
