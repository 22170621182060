import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteTeam } from "../services/teams.service";

export const useDeleteTeam = (teamId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteTeam(teamId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["teams"] });
    },
  });
};
