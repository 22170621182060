import { Store } from "../components/stores/types/Stores.types";
import { TeamData } from "../components/teams/types/Teams.types";
import { UserRole } from "../types/roles.types";

export const buildQueryParams = (params: Record<string, string | undefined>) => {
  const queryParams = new URLSearchParams();
  const keysWithSpaces = ["countryId", "storeId"];

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      if (keysWithSpaces.includes(key) && value.includes(" ")) continue;
      queryParams.append(key, value.toString());
    }
  }
  return queryParams.toString();
};

export function getRoleName(roleId: UserRole) {
  switch (roleId) {
    case "SUPERUSER":
      return "Global Superuser";
    case "COUNTRY_SUPERUSER":
      return "Country Superuser";
    case "COWORKER":
      return "Co-Worker";
    case "STOREOWNER":
      return "Store Superuser";
    default:
      return "";
  }
}

export function getStoreName(stores: Store[], storeId: string) {
  return stores?.find((store) => store.storeId === storeId)?.name;
}
export function checkForPqrTeamInStore(teamsInStore: TeamData[]) {
  return teamsInStore?.some((store) => store.is_product_quality) || false;
}

export function correctArticleNumberLength(item_no: string) {
  return item_no.toString().length < 8 ? item_no.toString().padStart(8, "0") : item_no;
}
