import Button from "@ingka/button";
import React, { useState } from "react";
import trashCan from "@ingka/ssr-icon/paths/trash-can";
import styled from "styled-components";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import { TaskLinks } from "../types/taskLinks.types";
import { Article } from "../types/Articles.types";
import { hasOngoingTasks } from "./ArticleRow.helper";

const DeleteButton = styled(Button)`
  color: #e00751;
  :hover {
    color: #e00751;
  }
`;

interface DeleteArticleModalProps {
  taskLinks: TaskLinks;
  article: Article;
  onDelete: () => Promise<void>;
}

export const DeleteArticleModal = ({ taskLinks, onDelete, article }: DeleteArticleModalProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <DeleteButton type={"tertiary"} text={"Delete"} ssrIcon={trashCan} onClick={() => setModalOpen(!modalOpen)} />
      <Modal visible={modalOpen} handleCloseBtn={() => setModalOpen(!modalOpen)}>
        <Prompt
          title={"Delete article"}
          titleId={"delete-article-modal"}
          header={<ModalHeader ariaCloseTxt={"Close modal"} />}
          footer={
            <ModalFooter>
              {hasOngoingTasks(taskLinks, article.productArticleId) ? (
                <Button text={"Cancel"} onClick={() => setModalOpen(!modalOpen)} />
              ) : (
                <Button
                  text={"Delete article"}
                  onClick={async () => {
                    await onDelete();
                    setModalOpen(!modalOpen);
                  }}
                />
              )}
            </ModalFooter>
          }
        >
          {hasOngoingTasks(taskLinks, article.productArticleId) ? (
            <p>There are ongoing tasks for this article. Please delete the article in the Fixa app instead.</p>
          ) : (
            <p>Are you sure you want to delete this article?</p>
          )}
        </Prompt>
      </Modal>
    </>
  );
};
