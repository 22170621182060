import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createUser } from "../services/users.service";
import { CreateUserType } from "../types/users.types";

export const useCreateUser = (user: CreateUserType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => createUser(user),
    onSuccess: () => {
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: ["users"] });
      }, 2000);
    },
  });
};
