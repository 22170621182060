import React from "react";
import arrowClockwise from "@ingka/ssr-icon/paths/arrow-clockwise";
import Button from "@ingka/button";
import { Article } from "../types/Articles.types";

interface UpdateArticleButtonProps {
  updateArticleButtonPressed: (article: Article) => void;
  article: Article;
}

export const UpdateArticleButton = ({ updateArticleButtonPressed, article }: UpdateArticleButtonProps) => {
  const handleUpdate = () => {
    updateArticleButtonPressed(article);
  };

  if (article.itemName.toLowerCase() !== "unknown") {
    return <></>;
  }
  return <Button ssrIcon={arrowClockwise} onClick={handleUpdate} text={"Update article"} type={"tertiary"} />;
};
